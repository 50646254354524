import React from 'react';
import Container from 'react-bootstrap/Container';
import { Link } from 'react-router-dom';
import NavBar from './NavBar';
import StepperNav from './StepperNav';

function Header() {
  return (
    <nav id="pctHeader" className="">
      <Container>
        <div className="row">
          <NavBar />
        </div>
      </Container>
      <nav id="headerIdentity" className="header-nav">
        <Container>
          <div className="row">
            <div className="d-flex mx-2 px-2 pb-2 justify-content-between">
              <Link to="/" className="text-decoration-none">
                <img
                  className="logo"
                  src="/images/cdf-waffle.svg"
                  alt="DISE Logo"
                  height="50"
                  style={{
                    display: 'inline-block',
                  }}
                />
                <span
                  style={{
                    color: 'white',
                    fontFamily: 'Tahoma',
                    fontSize: '1.5em',
                    paddingLeft: '5px',
                    verticalAlign: 'center',
                  }}
                >
                  <b>Common Data Fabric</b>
                </span>
              </Link>
              <span
                style={{
                  color: 'white',
                  fontFamily: 'Segoe UI, sans-serif',
                  fontWeight: 'normal',
                  fontSize: '1.5em',
                }}
              >
                CDF Portal
              </span>
            </div>
          </div>
        </Container>
        <div className="header-menu-container">
          <StepperNav></StepperNav>
        </div>
      </nav>
    </nav>
  );
}

export default Header;

import React from 'react';

const CDFhowDataPolicy = () => {
  return (
    <div>
      <h4>
        <img
          src="images/cdf-waffle.svg"
          style={{ display: 'inline-block', width: '50px' }}
          alt=""
        />
        &nbsp;How Does CDF Use Data Policy?
      </h4>
      <p>
        <img
          src="images/cdf-how-data-policy-1.png"
          style={{ width: '75%' }}
          alt=""
        />
      </p>
      <table cellPadding="10px">
        <tbody>
          <tr>
            <td style={{ width: '175px' }}>
              <p style={{ textAlign: 'center' }}>Natural Language Policy</p>
            </td>
            <td style={{ width: '125px' }}>
              <img src="images/cdf-how-data-policy-2.svg" alt="" />
            </td>
            <td style={{ width: '700px' }}>
              The Provider and Consumer systems’ Data Owners are responsible to
              complete and sign the DSA, aligning with accurate assessments of
              their data policies and their systems’ ability to protect the data
              according to policy
            </td>
          </tr>
          <tr>
            <td>
              <p style={{ textAlign: 'center' }}>Digital Policy</p>
            </td>
            <td>
              <img src="images/cdf-how-data-policy-3.svg" alt="" />
            </td>
            <td>
              CDF system makes no policy decisions. It transacts data sharing
              exactly and only as defined in the DSA, to protect the ISR data as
              digitally recorded in the CDF
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
export default CDFhowDataPolicy;

import React, { useState } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import { Link, useLocation } from 'react-router-dom';
import ModalForm from './ModalForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';

const StepperNav = () => {
  const location = useLocation();

  const allowedPaths = [
    '/onboard',
    '/tt4pinfo',
    '/tt4pinfoconsumer',
    '/tt4pinfoprovider',
    '/tt4pinterview',
    '/tt4pinterviewconsumer',
    '/tt4pinterviewprovider',
    '/dsagenerator',
  ];

  const stepper = allowedPaths.includes(location.pathname) ? true : false;

  const [modalOpen, setModalOpen] = useState(false);

  const handleModalOpen = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <div>
      {stepper && (
        <Navbar id="stepperNav" expand="sm" variant="dark" className="py-0">
          <Container>
            <Nav className="row row-cols-auto navbar-collapse mx-1">
              <Nav.Link
                className="col"
                as={Link}
                to="/onboard"
                active={location.pathname === '/onboard'}
              >
                <Button variant="primary" className="btn-med px-3 me-4">
                  Prepare
                </Button>
              </Nav.Link>
              <Nav.Link
                className="col"
                as={Link}
                to="/tt4pinfo"
                active={location.pathname === '/tt4pinfo'}
              >
                <span className="text-nowrap">
                  <Button variant="primary" className="btn-med px-1">
                    <Badge
                      pill
                      bg="warning"
                      text="primary"
                      className="position-relative top-0 start-0 translate-middle rounded-circle"
                    >
                      1
                    </Badge>
                    Enter Information
                    <FontAwesomeIcon
                      icon={faChevronRight}
                      color="white"
                      className="fa-fw ms-sm-3"
                    />
                  </Button>
                </span>
              </Nav.Link>
              <Nav.Link
                className="col"
                as={Link}
                to="/tt4pinterview"
                style={{ pointerEvents: 'none' }}
                active={location.pathname === '/tt4pinterview'}
              >
                <span className="text-nowrap">
                  <Button variant="primary" className="btn-med px-1">
                    <Badge
                      pill
                      bg="warning"
                      text="primary"
                      className="position-relative top-0 start-0 translate-middle rounded-circle"
                    >
                      2
                    </Badge>
                    Answer Questionnaire
                    <FontAwesomeIcon
                      icon={faChevronRight}
                      color="white"
                      className="fa-fw ms-sm-3"
                    />
                  </Button>
                </span>
              </Nav.Link>
              <Nav.Link
                className="col disabled"
                as={Link}
                to="/dsaGenerator"
                style={{ pointerEvents: 'none' }}
                active={location.pathname === '/dsagenerator'}
              >
                <span className="text-nowrap">
                  <Button variant="primary" className="btn-med px-1">
                    <Badge
                      pill
                      bg="warning"
                      text="primary"
                      className="position-relative top-0 start-0 translate-middle rounded-circle"
                    >
                      3
                    </Badge>
                    Generate DSA
                    <FontAwesomeIcon
                      icon={faChevronRight}
                      color="white"
                      className="fa-fw icon-invisible"
                    />
                  </Button>
                </span>
              </Nav.Link>
              {/* eslint-disable-next-line */}
              <a
                id="contactCdf"
                onClick={handleModalOpen}
                className="col nav-link pt-1"
                style={{ cursor: 'pointer' }}
              >
                <FontAwesomeIcon icon={faPhone} flip="vertical" color="white" />
                <b>&nbsp;Contact CDF Rep</b>
              </a>
            </Nav>
          </Container>
          <ModalForm modalOpen={modalOpen} handleModalOpen={handleModalOpen} />
        </Navbar>
      )}
    </div>
  );
  //  }
};

export default StepperNav;

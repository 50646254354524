import React from 'react';
import { InterviewPDFDropdown } from './rbsDropdown';

const Start = () => {
  return (
    <div id="onboardStart" className="homepage">
      <div>
        <div className="start">
          <img
            src="/images/onboarding-3step-new.svg"
            className="start-img"
            alt=""
            style={{
              // display: 'inline-block',
              marginLeft: '15px',
              width: '100%',
            }}
          />
        </div>
        <div className="text-center mt-4">
          <img
            src="images/cdf-waffle.svg"
            style={{ width: '30px' }}
            className="d-inline-block pb-2 me-1"
            alt=""
          />
          <p className="h3 d-inline-block">
            Preview the Policy Questionnaire:{' '}
          </p>
          <InterviewPDFDropdown className="position-absolute top-100 start-100 me-6" />
        </div>
      </div>
    </div>
  );
};

export default Start;

import { Resources } from './resources.js';

export const getRegistrationType = (registrationTypeOverride) => {
  // Remove session vars from localStorage
  // DEPRECATED - since no longer saving session vars to localStorage in tt4pinfo
  // for (let sessionKey of Object.keys(S.sessionKeys)) {
  //   if (localStorage.getItem(sessionKey)) {
  //     localStorage.removeItem(sessionKey);
  //   }
  // }
  let registrationType = '';
  if (registrationTypeOverride) {
    registrationType = registrationTypeOverride;
  } else if (sessionStorage.getItem('registrationType') !== null) {
    registrationType = sessionStorage.getItem('registrationType');
  } else {
    registrationType = 'consumer';
  }
  sessionStorage.setItem('registrationType', registrationType);
  return registrationType;
};

export const loadResource = (registrationType) => {
  console.log('DEBUG - resourceLoader.js loadResource()');
  if (Resources[registrationType] !== undefined) {
    return Resources[registrationType];
  } else {
    return Resources['missing'];
  }
};

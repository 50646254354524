import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function changeRegistrationType(e) {
  // Any change to registrationType  makes session 'dirty' - Skip if no change
  if (sessionStorage.getItem('registrationType') !== e.currentTarget.id) {
    console.log('DEBUG - TT4PNavBar.js - set DIRTY');
    sessionStorage.setItem('dirtyCheck', 'dirty');
    const tt4pInterviewNode = document.querySelector(
      'a[href="/tt4pinterview"]',
    );
    tt4pInterviewNode.style.pointerEvents =
      tt4pInterviewNode.classList.contains('active') ? 'auto' : 'none';
  }
  // Save registrationType
  // TODO - This is redundant with tt4pinfo initialization?
  sessionStorage.setItem('registrationType', e.currentTarget.id);
}

const TT4PNavBar = () => {
  const registrationType = sessionStorage.getItem('registrationType');

  return (
    <Navbar id="registrationBar" className="" variant="dark">
      <Nav className="mx-auto">
        <Nav.Link
          id="consumer"
          as={Link}
          to="/tt4pinfoconsumer"
          className="nav-link"
          style={{ display: 'inline' }}
          onClick={changeRegistrationType}
          active={registrationType === 'consumer'}
        >
          Register as
          <span className="registration-type"> Data Consumer</span>
        </Nav.Link>
        <span className="choose">OR</span>
        <Nav.Link
          id="provider"
          as={Link}
          to="/tt4pinfoprovider"
          className="nav-link"
          style={{ display: 'inline' }}
          onClick={changeRegistrationType}
          active={registrationType === 'provider'}
        >
          Register as
          <span className="registration-type"> Data Provider</span>
        </Nav.Link>
      </Nav>
    </Navbar>
  );
};

export default TT4PNavBar;

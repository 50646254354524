import React from 'react';

const CDFtoDIE = () => {
  return (
    <div>
      <h4>
        <img
          src="images/cdf-waffle.svg"
          style={{ display: 'inline-block', width: '50px' }}
          alt=""
        />
        &nbsp;CDF Support to DIE
      </h4>
      <span>CDF supports the DIE digital transformation by:</span>
      <table cellPadding="10px">
        <tbody>
          <tr>
            <td style={{ width: '450px', paddingRight: '30px' }}>
              <ul>
                <li>
                  {/* Automatic data availability to all CCMD, Services, Agencies,
                  FVEY, and Mission Partners’ consuming systems based on data
                  policy */}
                  Brokering TCPED data between all CCMD, Service, CSA, and FVEY
                  systems
                </li>
                <li>
                  {/* All CDF data available for streaming and batching via a single
                  ATO and connection */}
                  Providing a scalable approach to achieving high network
                  density required for multi-domain operations
                </li>
                <li>
                  {/* Data Owners retain data access decisions, promulgated through
                  CDF via policy */}
                  Automating data access decisions and implementation via
                  policy-based access
                </li>
                <li>
                  {/* Expanded data resources accessible for AI/ML activities */}
                  Greatly reducing the time & resources required to integrate
                  new systems
                </li>
                {/* <li>
                  New data exchanges to augment PED across services, agencies,
                  FVEY, and mission partners, with verified data protection
                  capabilities
                </li> */}
              </ul>
            </td>
            <td>
              <img src="images/cdf-to-die.png" alt="" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default CDFtoDIE;

import React from 'react';
import { Settings } from '../../settings';

const CDFIOG = () => {
  const iogPDF = window.PCT_ENVIRONMENT.cdfIogFile
    ? window.PCT_ENVIRONMENT.cdfIogFile
    : Settings.cdfIogFile;

  return (
    <div>
      <iframe src={iogPDF} width="100%" height="1000" title="pdfview"></iframe>
    </div>
  );
};
export default CDFIOG;

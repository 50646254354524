import React, { useContext } from 'react';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Form from 'react-bootstrap/Form';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import OptionsMenuContext from './OptionsMenuContext.js';
import './OptionsMenu.css';

export default function OptionsMenu() {
  const [optionsMenu, setOptionsMenu] = useContext(OptionsMenuContext);
  const { options, showOptionsMenu } = optionsMenu;

  function handleClose() {
    setOptionsMenu({ ...optionsMenu, showOptionsMenu: false });
  }

  return (
    <div id={'div-options'}>
      <Offcanvas
        id="offCanvas-optionsMenu"
        // eslint-disable-next-line
        show={showOptionsMenu}
        // eslint-disable-next-line
        onHide={handleClose}
        placement="end"
      >
        <Offcanvas.Header id="offCanvasHeader-optionsMenu" closeButton>
          <div id="div-optionsMenu">
            <Offcanvas.Title id="offCanvasTitle-optionsMenu">
              Options Menu
            </Offcanvas.Title>
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body id="offCanvasBody-optionsMenu">
          <div id="div-form-optionsMenu">
            <Form id="form-optionsMenu" className="offCanvasOptionsForm">
              {/* eslint-disable-next-line */}
              {options.map((option) => {
                // eslint-disable-next-line
                return option.type === 'button' ? (
                  <div
                    key={`div-${option.btnID}`}
                    id={`div-${option.btnID}`}
                    className="offCanvasOptionsForm"
                  >
                    <OverlayTrigger
                      id={`overlay-${option.btnID}`}
                      placement="left"
                      overlay={
                        <Tooltip id={`tooltip-${option.btnID}`}>
                          {option.btnToolTip}
                        </Tooltip>
                      }
                    >
                      <Button
                        id={`button-${option.btnID}`}
                        key={`button-${option.btnID}`}
                        onClick={option.btnOnClick}
                        className="optionsMenuBtn"
                      >
                        {option.btnLabel}
                      </Button>
                    </OverlayTrigger>
                  </div>
                ) : (
                  <div
                    key={`div-${option.btnID}`}
                    id={`div-${option.btnID}`}
                    className="offCanvasOptionsForm"
                  >
                    <OverlayTrigger
                      id={`overlay-${option.btnID}`}
                      placement="left"
                      overlay={
                        <Tooltip id={`tooltip-${option.btnID}`}>
                          {option.btnToolTip}
                        </Tooltip>
                      }
                    >
                      <Form.Check
                        id={`button-${option.btnID}`}
                        type="switch"
                        key={`button-${option.btnID}`}
                        defaultChecked={option.isChecked}
                        onClick={option.btnOnClick}
                        className="optionsMenuBtn"
                        label={option.btnLabel}
                      />
                    </OverlayTrigger>
                  </div>
                );
              })}
            </Form>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
}

import blobStream from 'blob-stream';
import PDFDocument from 'pdfkit/js/pdfkit.standalone.js';
import { dsaGeneratorFunctions } from './dsa-generator';
import { dlInterviewGeneratorFunctions } from './dl-interview-generator';

export const GeneratorTypes = {
  DSA: 'dsa',
  DL_INTERVIEW: 'dl_interview',
};

/*
 * Factory function that creates and returns a new pdfGenerator object.

 * Params:
 *  
 *    - generator_type: See GeneratorTypes object for available values.
 *    - template: Object that defines the template to be used to create the PDF. Must match the generator type.
 *    - data: Object containing the data that will be use to fill in the PDF template
 *    - fonts: An array of font objects. Each unique font listed in the template must have a 
 *      corresponding font object. Font objects must have the following fields:
 *          - name: The name of the font as it appears in the template. 
 *          - buffer: A buffer object of the font. 
 *
 */
export function createPDFGenerator({ generator_type, template, data, fonts }) {
  let newGenerator;
  if (generator_type === GeneratorTypes.DSA) {
    newGenerator = Object.create(dsaGeneratorFunctions);
  } else if (generator_type === GeneratorTypes.DL_INTERVIEW) {
    newGenerator = Object.create(dlInterviewGeneratorFunctions);
  }
  const newDocument = new PDFDocument({
    size: template.documentSettings.pageSize,
    margin: template.documentSettings.margin,
    pdfVersion: '1.7',
    displayTitle: true,
    bufferPages: true,
  });

  newDocument.info.Title = template.documentSettings.metadata.version;
  newDocument.info.Creator = template.documentSettings.metadata.creator;
  newDocument.info.Producer = 'PDF Generator';

  // Document must be piped to a writable blob stream so that it can be worked with from within the browser.
  const newDocumentStream = newDocument.pipe(blobStream());

  newGenerator.document = newDocument;
  newGenerator.documentStream = newDocumentStream;
  newGenerator.template = template;
  newGenerator.data = data;
  newGenerator.fonts = fonts;

  return newGenerator;
}

import React from 'react';

//const policyListDefault = '';

const PolicyDiffViewer = () => {
  //// Get Previous policy list from localStorage
  //const policyListPrevious =
  //  localStorage.getItem('policyList') !== null
  //    ? localStorage.getItem('policyList')
  //    : policyListDefault;

  //// Get & store New policy list
  //const policyListNew = props.policyListNew;
  //localStorage.setItem('policyList', policyListNew);

  //const diffHtml = PolicyDiff(policyListPrevious, policyListNew);

  // REMOVED FROM JSX below in favor of manually forcing innerHTML in TT4PInterview.js
  // dangerouslySetInnerHTML={{ __html: diffHtml }}
  return <div id="policyDiff" className="current-document" />;
};

export default PolicyDiffViewer;

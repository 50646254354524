export const Settings = {
  cdfIogFile: '/configs/docs/cdf_integrated_operations_guide.pdf',
  sessionKeys: {
    franchise: {
      default: 'prime',
      allowable: ['prime', 'nga'],
    },
    networkLabel: {
      default: '',
      allowable: [],
    },
    registrationType: {
      default: 'consumer',
      allowable: ['consumer', 'provider'],
    },
    redmineProjectId: {
      default: '',
      allowable: ['1', '2', '3'],
    },
    redmineTrackerId: {
      default: '',
      allowable: [],
    },
    redmineTrackerName: {
      default: '',
      allowable: [],
    },
    redmineSubject: {
      default: '',
      allowable: [],
    },
    sendNotification: {
      default: '',
      allowable: ['send', 'skip'],
    },
    testMode: {
      default: 'sendNormal',
      allowable: ['sendNormal', 'sendTest', 'sendNone'],
    },
    dirtyCheck: {
      default: 'dirty',
      allowable: ['dirty', 'clean'],
    },
    interviewFile: {
      default: '',
      allowable: [],
    },
    policiesFile: {
      default: '',
      allowable: [],
    },
    dsaGenTemplate: {
      default: '',
      allowable: [],
    },
    dsaTemplate0: {
      default: '',
      allowable: [],
    },
    dsaTemplate1: {
      default: '',
      allowable: [],
    },
    dsaTemplate2: {
      default: '',
      allowable: [],
    },
    dsaTemplateFilename: {
      default: '',
      allowable: [],
    },
    quickFillRegistrationFile: {
      default: '',
      allowable: [],
    },
    quickFillInterviewFile: {
      default: '',
      allowable: [],
    },
    pctHelpDeskEmail: {
      default: '',
      allowable: [],
    },
    providerInitialRegistrationSubmitError: {
      default: '',
      allowable: [],
    },
    providerInterviewSubmitError: {
      default: '',
      allowable: [],
    },
    providerInterviewSubmitSuccess: {
      default: '',
      allowable: [],
    },
    providerDsaErrorInfo: {
      default: '',
      allowable: [],
    },
    providerDsaDownloadSuccess: {
      default: '',
      allowable: [],
    },
    consumerInterviewSubmitError: {
      default: '',
      allowable: [],
    },
    consumerInterviewSubmitSuccess: {
      default: '',
      allowable: [],
    },
    consumerDsaErrorInfo: {
      default: '',
      allowable: [],
    },
    consumerDsaDownloadSuccess: {
      default: '',
      allowable: [],
    },
    providerRegistrationStep: {
      default: '',
      allowable: ['initial', 'final'],
      comment: 'provider_visitPurpose = firstVisit or returnVisit',
    },
  },
  resourcesUrl: '/configs/resources.json',
  localStorageAnswersKeyPrefix: 'tt4pAnswers',
  defaultFranchise: 'prime',
  defaultRegistrationType: 'consumer',
  policyFragmentPrefixConsumer: '- The consuming system shall ',
  policyFragmentPrefixProvider:
    '- The dataset provider requires any consuming system to ',
  policyFragmentSuffix: '.\n',
  localStorageTestModeKey: 'testMode',
  defaultTestMode: 'sendNormal',
  localStorageDirtyCheckKey: 'dirtyCheck',
  defaultDirtyCheck: 'dirty',
  messages: {
    consumer: {},
    provider: {
      first: '',
      return: '',
    },
    dsaAutoDownloaded:
      'Your agreement has been automatically downloaded to your computer. Please check your downloads folder or designated download location to access it.',
    interviewSubmission: 'Submission was successful.',
    interviewSubmission2:
      'Your information is being sent to the CDF team for follow-up.\nA representative will contact you shortly to begin the data technical onboarding process.',
    demographicsSubmission: 'Submission was successful.',
    helpGeneral: 'Help request was submitted',
  },
  dsa: {
    digSigLocatorStr1: 'TEST ONLY 1',
    digSigLocatorStr2: 'TEST ONLY 2',
    consumer: {
      dsaMaxLinesPage1: 41,
      dsaMaxLineWidth: 535,
    },
    provider: {
      dsaMaxLinesPage1: 41,
      dsaMaxLineWidth: 535,
    },
  },
};

import React from 'react';

const CDFwhat = () => {
  return (
    <div>
      {/* Notes from Bill Bal:
          - Needs source for graphic. This is a very good graphic for illustrating CDF
          - Refer to CDF as "The CDF" or just "CDF"?
          - With exception of 3rd bullet, the rest are genera;ozed enough to apply to other 
            existing data related programs(ex: DIB, UDI, ADVANA) 
            - Need to describe CDF in a unique way*/}
      <h4>
        <img
          src="images/cdf-waffle.svg"
          style={{ display: 'inline-block', width: '50px' }}
          alt=""
        />
        &nbsp;What is CDF?
      </h4>
      <table cellPadding="10px">
        <tbody>
          <tr>
            <td style={{ width: '450px', paddingRight: '30px' }}>
              <p>
                <b>Common Data Fabric</b> (CDF) is a software-based capability
                that functions as a machine-to-machine{' '}
                <b>
                  <i>enterprise data broker</i>
                </b>{' '}
                between data sources and consumer systems.
              </p>
              <ul>
                <li>
                  Utilizes 100% Commercial Software and DoD CIO-approved IC
                  Cloud Services
                </li>
                <li>
                  Brokers Data at Multiple Security Levels; Does not Co-Mingle
                  Data
                </li>
                <li>Accelerates Data Sharing via Machine-Enforced Policy</li>
                <li>
                  Enables Rapid, Automated Sharing of Massive Datasets for
                  Artificial Intelligence, Machine Learning and Other Data
                  Intensive Applications
                </li>
              </ul>
            </td>
            <td>
              <img
                src="images/cdf-what-1.png"
                style={{ width: '75%' }}
                alt=""
              />
            </td>
          </tr>
        </tbody>
      </table>
      <table cellPadding="10px">
        <tbody>
          <tr>
            <td className="textBlock">
              <span>
                CDF is a key component of the digital foundation enabling U.S.
                Forces operating in a Joint Environment, access to DoD ISR data
                across unique mission boundaries including Services, Combatant
                Commands and Combat Support Agencies, as well as Mission
                Partners and Allies.
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      <h4>{/* <img src="images/cdf-what-2.svg" /> */}</h4>
      <h4>Operational Perspective</h4>
      <table cellPadding="10px">
        <tbody>
          <tr>
            <td className="thirds">
              Part of USD(I&amp;S)’ Digital Transformation campaign
            </td>
            <td className="thirds">A DIA Program of Record</td>
            <td className="thirds">
              A Defense Intelligence Enterprise (DIE) service
            </td>
          </tr>
          <tr>
            <td className="thirds">
              <img src="images/cdf-what-3.png" alt="" />
            </td>
            <td className="thirds">
              <img
                src="images/cdf-what-4.png"
                style={{ width: '75%' }}
                alt=""
              />
            </td>
            <td className="thirds">
              <img src="images/cdf-what-1.png" alt="" />
            </td>
          </tr>
          <tr>
            <td className="thirds">
              <ul>
                <li>
                  {/* An Enterprise data broker supporting modernization of the
                  Defense Intel Enterprise (DIE) */}
                  An Enterprise data broker supporting Project Herald’s Digital
                  Foundation
                </li>
                <li>
                  {/* A service to automate connectivity between intelligence
                  systems */}
                  An enterprise service enabling Machine-to-Machine (M2M) data
                  sharing
                </li>
                <li>
                  {/* A Critical capability necessary for Multi-Domain Operations */}
                  Scalable data brokering for Multi-Domain Operations
                </li>
              </ul>
            </td>
            <td className="thirds">
              <ul>
                <li>
                  DIA is the executive agent for CDF development and deployment
                </li>
                <li>
                  A Program of Record started in 2020
                  {/* running through 2028? */}
                </li>
                <li>Executed through two contracts: Diamond and Coalminer</li>
              </ul>
            </td>
            <td className="thirds">
              <ul>
                <li>
                  {/* Operates below the data storage and application layers of the
                  DIE */}
                  Operates below the data storage layer of the enterprise
                  architecture
                </li>
                <li>
                  100% commercial software – requiring no govt development
                </li>
                <li>Fully accredited and operational on SIPRNET and JWICS</li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
      <table cellPadding="10px">
        <tbody>
          <tr>
            <td className="textBlock" style={{ textAlign: 'left' }}>
              <span>
                <b>Intelligence</b> data poses unique challenges to effective
                sharing – CDF was built to mitigate each
                <ul>
                  <li>
                    <b>Policy</b> limits intelligence data distribution –{' '}
                    <u>
                      CDF leverages data policy to ensure security compliance
                    </u>
                  </li>
                  <li>
                    <b>Network and system boundaries</b> complicate system
                    connectivity –{' '}
                    <u>
                      CDF brokers these connections so programs don’t have to
                    </u>
                  </li>
                  <li>
                    <b>ICD & DOD-defined responsibilities</b> for data providers
                    and consumers –{' '}
                    <u>
                      CDF uses compliance as a tool to facilitate connectivity
                    </u>
                  </li>
                </ul>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      <p>{/* <img src="images/cdf-what-5.svg" /> */}</p>
      <h4>Project Herald</h4>
      <p>CDF is a USD(I&amp;S) initiative supporting Project Herald</p>
      <table>
        <tbody>
          <tr>
            <td style={{ width: '400px', paddingRight: '30px' }}>
              <ul>
                <li style={{ marginBottom: '15px' }}>
                  <b>The Common Data Fabric (CDF)</b> is an enabling service
                  supporting the <b>USD(I&amp;S)</b>
                  Defense Intelligence Digital Transformation Campaign –{' '}
                  <b>
                    <i>Project Herald</i>
                  </b>
                </li>
                <li>
                  CDF is an <b>Enterprise Data Broker</b> supporting Project
                  Herald’s Digital Foundation
                </li>
              </ul>
            </td>
            <td>
              <img
                src="images/cdf-what-6.png"
                style={{ width: '75%' }}
                alt=""
              />
            </td>
          </tr>
        </tbody>
      </table>
      <span>&nbsp;</span>
      <table cellPadding="10px">
        <tbody>
          <tr>
            <td className="textBlock">
              <span>
                The defense intelligence digital transformation campaign—known
                as Project Herald—will evolve how the Department of Defense
                (DoD) delivers intelligence to warfighters while providing the
                unifying focus to realize next-generation capabilities at the
                speed of war.
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      <p>{/* <img src="images/cdf-what-7.svg" /> */}</p>
    </div>
  );
};

export default CDFwhat;

import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Home from './Home';
import Start from './Start';
import Learn from './Learn';
import TT4Pinfo from './TT4Pinfo';
import TT4PInterview from './TT4PInterview';
import CDFIOG from './static-pages/CDFIOG';
import DSAGenerator from './DSAGenerator';
import Utility from './Utility';

function Main() {
  const routes = [
    { path: '/', component: Home, exact: true },
    { path: '/learn', component: Learn },
    { path: '/onboard', component: Start },
    { path: '/tt4pinfo', component: TT4Pinfo },
    { path: '/tt4pinfoconsumer', component: TT4Pinfo },
    { path: '/tt4pinfoprovider', component: TT4Pinfo },
    { path: '/tt4pinterview', component: TT4PInterview },
    { path: '/CDFIOG', component: CDFIOG },
    { path: '/dsagenerator', component: DSAGenerator },
    { path: '/utility', component: Utility },
  ];

  return (
    <main id="mainRoutePaths">
      <Switch>
        {routes.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            component={route.component}
            exact={route.exact}
          />
        ))}
        <Redirect to="/" />
      </Switch>
    </main>
  );
}

export default Main;

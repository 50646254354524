import React from 'react';

function ClassificationBanner() {
  let classhead =
    window.PCT_ENVIRONMENT.environments[
      window.PCT_ENVIRONMENT.classificationBanner
    ].classhead;
  let bannerDisplay =
    window.PCT_ENVIRONMENT.environments[
      window.PCT_ENVIRONMENT.classificationBanner
    ].bannerDisplay;

  return (
    <div id="classificationBanner" className={`row mx-0 ${classhead}`}>
      <strong>{bannerDisplay}</strong>
    </div>
  );
}
//combine with email settings
export default ClassificationBanner;

import React from 'react';
import ReactDOM from 'react-dom';
import './App.css';
import App from './components/App';
import { BrowserRouter as Router } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';
import './index.css';
import './styles.css';
import ClassificationBanner from './components/ClassificationBanner';
import { ModalAlertQueue } from './components/ModalAlertQueue.js';

const rootElement = document.getElementById('root');
ReactDOM.render(
  <Router>
    <div id="routerWrapper" className="container-fluid gx-0">
      <ClassificationBanner />
      {/* ScrollToTop is a fix for page transitions not starting at top of the page */}
      <ScrollToTop>
        <ModalAlertQueue>
          <App />
        </ModalAlertQueue>
      </ScrollToTop>
    </div>
  </Router>,
  rootElement,
);

import { Navbar, Nav } from 'react-bootstrap';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const NavBar = () => {
  let navLocation = useLocation();

  return (
    <div id="navbarTopContainer" className="px-0">
      <Navbar
        id="navbarTop"
        variant="dark"
        className="align-items-end justify-content-between py-0 px-0"
      >
        <div className="top-0 end-0">
          <Nav className="">
            <Nav.Link
              as={Link}
              to="/home"
              active={
                navLocation.pathname === '/' || navLocation.pathname === '/home'
              }
            >
              Home
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/learn"
              active={navLocation.pathname === '/learn'}
            >
              Learn
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/onboard"
              active={
                navLocation.pathname === '/onboard' ||
                navLocation.pathname === '/tt4pinfo' ||
                navLocation.pathname === '/tt4pinfoconsumer' ||
                navLocation.pathname === '/tt4pinfoprovider' ||
                navLocation.pathname === '/tt4pinterview' ||
                navLocation.pathname === '/dsagenerator'
              }
            >
              Register
            </Nav.Link>
            <Nav.Link
              href="https://ousdi.defense.gov/"
              target="_blank"
              active={false}
            >
              OUSD(I&amp;S)
            </Nav.Link>
          </Nav>
        </div>
      </Navbar>
    </div>
  );
};

export default NavBar;

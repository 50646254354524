import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import { postRedmine } from '../redmine-utils.js';
import { RedmineSettings } from '../redmine-settings.js';
import PropTypes from 'prop-types';

function ModalForm(props) {
  //check env variable
  //if tne, xx email
  //if jwics, oo email
  //if siprnet, aa email

  //let email = Settings.environment[window.PCT_ENVIRONMENT.environment].email;

  const [isSubmitDisabled, setSubmitDisabled] = useState(false);
  const [validated, setValidated] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState('danger');
  const [alertHeading, setAlertHeading] = useState('');
  const [alertText, setAlertText] = useState('');

  const [closeButtonLabel, setCloseButtonLabel] = useState('Cancel');

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      // Invalid
      setValidated(true);
      event.preventDefault();
      event.stopPropagation();
    } else {
      // Valid
      postRedmine({
        issue: {
          subject: 'Help Request',
          description: `
            
            Help Request Category: 
             ${event.target.elements[0].selectedOptions[0].label}
            
            Name:
             ${event.target.elements.name.value}
            
            Phone:
             ${event.target.elements.phone.value}
            
            Email:
             ${event.target.elements.email.value}
            
            Message:
             ${event.target.elements.message.value}`,
          custom_fields: [
            {
              id: RedmineSettings.redmineCustomFields['Help Request Category']
                .id,
              value: event.target.elements[0].selectedOptions[0].value,
            },
          ],
          tracker_id: RedmineSettings.redmineHelpTracker.id, // PCT Help Request
        },
      })
        .then((response) => {
          if (!response.ok) {
            console.log(
              `DEBUG - Redmine error: '${response.status}': '${response.statusText}'`,
            );
            if (response.status) {
              throw new Error(window.PCT_ENVIRONMENT.modalFormSubmitError);
            }
          }
          console.log('DEBUG - Redmine then response', response);
          return response.json();
        })
        .then((data) => {
          // Handle successful response
          console.log('DEBUG - Redmine then result', data);

          setAlertHeading('Thank you!');
          setAlertType('success');
          setAlertText(window.PCT_ENVIRONMENT.modalFormSubmitSuccess);
          setShowAlert(true);
          setCloseButtonLabel('Close');
          form.reset();
          setSubmitDisabled(true);
        })
        .catch((error) => {
          // Don't use ModalAlert here because we want don't want a modal on top of a modal.
          setAlertHeading('Error');
          setAlertType('danger');
          setAlertText(error.message);
          setShowAlert(true);
          setCloseButtonLabel('Cancel');
        });
    }
    event.preventDefault();
  };

  const handleModalCloseWithReset = () => {
    // Perform additional tasks here, such as resetting the modal state
    // Close modal popup, but first reset state of modal
    setValidated(false);
    setShowAlert(false);
    setAlertType('danger');
    setAlertHeading('');
    setAlertText('');
    setSubmitDisabled(false);
    // Call the original handleModalClose function
    props.handleModalOpen();
  };

  const checkTextForHelpEmail = () => {
    const helpDeskRegex = /({{pctHelpDeskEmail}})/g;
    const email = window.PCT_ENVIRONMENT.pctHelpDeskEmail;

    if (alertText.includes('{{pctHelpDeskEmail}}')) {
      const parts = alertText.split(helpDeskRegex);
      const elements = parts.map((part, i) => {
        if (part === '{{pctHelpDeskEmail}}') {
          return (
            <Alert.Link key={i} href={`mailto:${email}`}>
              {email}
            </Alert.Link>
          );
        } else {
          return <span key={i}>{part}</span>;
        }
      });
      return elements;
    }
    return alertText;
  };

  return (
    <>
      <Modal
        // eslint-disable-next-line
        show={props.modalOpen}
        onHide={handleModalCloseWithReset}
        centered
        id="pctModalForm"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <span className="text-primary">Contact Us</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert
            show={showAlert}
            variant={alertType}
            onClose={() => {
              setShowAlert(false);
            }}
            dismissible
          >
            <Alert.Heading>{alertHeading}</Alert.Heading>
            <p>{checkTextForHelpEmail()}</p>
          </Alert>
          <p className="text-center">
            Feel free to contact us using the form below if you need any
            assistance or have any questions about the onboarding process.
            <br />A CDF Representative will contact you shortly.
          </p>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Group controlId="validationCustom01">
              <Form.Select className="mb-2" required>
                <option value="">Select type of Issue</option>
                <option value="policy">Policy Assistance</option>
                <option value="onboarding">Onboarding Assistance</option>
                <option value="other">Other</option>
              </Form.Select>
              <Form.Control.Feedback type="invalid" className="mb-1">
                Please select an issue type
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Control
                type="name"
                id="name"
                className="mb-1"
                placeholder="Name"
                required
              />
              <Form.Control.Feedback type="invalid" className="mb-1">
                Please enter a name.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Control
                type="tel"
                id="phone"
                className="mb-1"
                placeholder="Phone"
              />
            </Form.Group>
            <Form.Group>
              <Form.Control
                type="email"
                id="email"
                className="mb-1"
                placeholder="Email"
                required
              />
              <Form.Control.Feedback type="invalid" className="mb-1">
                Please provide an email.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Control
                type="text"
                id="message"
                className="mb-1"
                placeholder="Message"
                as="textarea"
                rows={5}
                required
              />
              <Form.Control.Feedback type="invalid" className="mb-1">
                Please provide a brief message describing your question or
                issue.
              </Form.Control.Feedback>
            </Form.Group>
            <Button
              variant="primary"
              type="submit"
              className="w-25 me-2"
              disabled={isSubmitDisabled}
            >
              Submit
            </Button>
            <Button
              variant="secondary"
              type="button"
              className="ml-3 w-25"
              onClick={handleModalCloseWithReset}
            >
              {closeButtonLabel}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

ModalForm.propTypes = {
  handleModalOpen: PropTypes.func.isRequired,
};

export default ModalForm;

import { createContext } from 'react';

const PCTAlertContext = createContext({
  show: false,
  variant: '',
  heading: '',
  text: '',
  info: '',
  buttons: [],
  onClose: () => {},
});

export default PCTAlertContext;

import React from 'react';

const CDFtoISR = () => {
  return (
    <div>
      <h4>
        <img
          src="images/cdf-waffle.svg"
          style={{ display: 'inline-block', width: '50px' }}
          alt=""
        />
        &nbsp;CDF Support to ISR
      </h4>
      <p>CDF Support to ISR Mission Execution</p>
      <table cellPadding="10px">
        <tbody>
          <tr>
            <td className="ISR">
              <div>
                <b>Today,</b> there are at least nine different end-to-end
                systems across the DIE that provide functions to execute
                Full-Motion Video (FMV) exploitation. These are all uniquely
                programmed and designed to serve different FMV exploiters.
                However, the disparity between these systems provides training
                challenges for analysts, limit the integration of advanced
                capabilities, and fail to provide the end-consumer with
                materially unique capabilities. This results in significant
                duplication of maintenance efforts and does not provide
                organizations unique value-added capability that might be
                incorporated into an FMV workflow.
              </div>
            </td>
            <td style={{ width: '20%', paddingRight: '20px' }}>
              <img src="images/arrow.svg" alt="" />
            </td>
            <td className="ISR" rowSpan="2" style={{ paddingBottom: '65px' }}>
              <div>
                <b>In the Future,</b> a single Motion GEOINT Exploitation
                Product is available enterprise-wide that: Enables best-of-breed
                Motion GEOINT exploitation techniques and tools for use by the
                entire community. Ensures compliance with the functional
                manager&apos;s standards for data marking, data quality, data
                storage, production tradecraft, and Structure Observation
                Management (SOM) creation. Ensures data is postured for maximum
                AI/ML exploitation in shared repositories, which should include
                track data repositories.
              </div>
              <div>
                Allows scale by prioritizing open-source software, or at least
                enterprise licensing for commercial products. Enables individual
                organizations to focus on value- added capabilities (e.g., Naval
                forces developing littoral detection algorithms).
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan="1">
              <table>
                <tbody>
                  <tr>
                    <td
                      className="textBlock"
                      style={{ fontSize: '8pt', width: '12.5%' }}
                    >
                      <span>
                        The CDF is a Core component of Army Intelligence
                        Enterprise Transformation in the Unified Data
                        Environment (UDE)
                      </span>
                    </td>

                    <td style={{ width: '15%' }}>
                      <img src="images/cdf-isr-2.png" alt="" />
                    </td>

                    <td style={{ width: '15%' }}>
                      <img src="images/cdf-isr-3.png" alt="" />
                    </td>

                    <td
                      className="textBlock"
                      style={{ fontSize: '8pt', width: '12.5%' }}
                    >
                      <span>
                        The CDF has demonstrated integration with JADC2
                      </span>
                    </td>
                  </tr>
                  {/* <img className="isr-img" src="images/cdf-isr-1.svg" />
              <img className="isr-img" src="images/cdf-isr-2.png" />
              <img className="isr-img" src="images/cdf-isr-3.png" />
              <img className="isr-img" src="images/cdf-isr-4.svg" /> */}
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
export default CDFtoISR;

import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRocket, faBook, faPhone } from '@fortawesome/free-solid-svg-icons';
import ModalForm from './ModalForm';

const Home = () => {
  // eslint-disable-next-line
  const [pathname, setPathname] = useState('/');
  const location = useLocation();

  useEffect(() => {
    setPathname(location.pathname);
  }, [location]);

  // Modal Help Form
  const [modalOpen, setModalOpen] = useState(false);
  const handleModalOpen = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <div id="pctHome" className="home">
      <table
        cellPadding="20"
        style={{ marginLeft: 'auto', marginRight: 'auto' }}
      >
        <tbody>
          <tr valign="top">
            <td style={{ minWidth: '300px', maxWidth: '400px' }}>
              <h4>
                What is CDF?&nbsp;
                <img
                  src="images/cdf-waffle.svg"
                  style={{ display: 'inline-block', width: '30px' }}
                  alt=""
                />
              </h4>
              <p>
                <b>Common Data Fabric (CDF)</b> is a computer software-solution
                that brokers and protects intelligence data transmitted between
                intelligence producers, warfighters, planners, and policy
                makers.
              </p>
              <ul>
                <li>
                  Replaces Legacy Data Sharing Processes (&amp; Human Decisions)
                  with Machine Enforced Data Sharing
                </li>
                <li>Data policies are enforced by the CDF</li>
                <li>Operates Machine to Machine (M2M)</li>
                <li>Utilizes 100% Commercial Software</li>
                <li>Data is highly available</li>
              </ul>
              <h4>
                Benefits&nbsp;
                <img
                  src="images/cdf-waffle.svg"
                  style={{ display: 'inline-block', width: '30px' }}
                  alt=""
                />
              </h4>
              <ul>
                <li>
                  Analyst access more ISR Data through a single ATO’d connection
                </li>
                <li>
                  Provider retains control over data access policies – CDF
                  simply implements and enforces providers policies in software
                  to automate rapid Data Brokering decisions
                </li>
                <li>
                  Providers May Change Their Data Policy and CDF Notifies
                  End-Points of Changes and Enforces New Policy Across the
                  Enterprise{' '}
                </li>
                <li>Fewer ATOs and End-Points required to maintain</li>
                <li>
                  Attack surfaces decreased; cyber risk reduced due to fewer
                  connected end-points to secure
                </li>
                <li>
                  Consumer Machines Have Immediate Access to New Data Resources
                  Without prior knowledge of the data’s existence or human to
                  human requests to Providers
                </li>
                <li>
                  Data providers can seamlessly modify ATO boundaries without
                  impacting consumer ATOs
                </li>
              </ul>
            </td>
            <td style={{ minWidth: '300px', maxWidth: '600px' }}>
              <h4>
                Key Objectives&nbsp;
                <img
                  src="images/cdf-waffle.svg"
                  style={{ display: 'inline-block', width: '30px' }}
                  alt=""
                />
              </h4>
              <ul>
                <li>
                  {/* Deliver more agile operational architectures for the
                  warfighter */}
                  Support development of more agile operational warfighting
                  concepts
                </li>
                <li>
                  {/* Automate DoD and IC data sharing */}
                  Automate DoD and IC data sharing between systems
                </li>
                <li>Implement ICD 501 and IC Information Sharing Strategy</li>
                <li>
                  Demonstrate emerging policy-based, machine-to-machine data
                  sharing with international partners
                </li>
              </ul>

              <h4>
                CDF Operational View&nbsp;{' '}
                <img
                  src="images/cdf-waffle.svg"
                  style={{ display: 'inline-block', width: '30px' }}
                  alt=""
                />
              </h4>
              <br />
              <img
                src="images/cdf-operational-view-diagram.png"
                style={{ minWidth: '500px', maxWidth: '600px' }}
                alt=""
              />
              <br />
              <table
                cellPadding="10"
                style={{ marginLeft: 'auto', marginRight: 'auto' }}
              >
                <tbody>
                  <tr>
                    <td style={{ width: '30px' }}>
                      <FontAwesomeIcon
                        icon={faRocket}
                        size="2x"
                        color="#ed7d31ff"
                      />{' '}
                    </td>
                    <td style={{ width: '500px' }}>
                      <div className="links">
                        <h5>
                          <a href="./onboard">
                            Click here to start CDF Onboarding
                          </a>
                        </h5>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <FontAwesomeIcon
                        icon={faBook}
                        size="2x"
                        color="#ed7d31ff"
                      />{' '}
                    </td>
                    <td>
                      <div className="links">
                        <h5>
                          <a href="./learn">
                            Click here to learn more about CDF
                          </a>
                        </h5>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <FontAwesomeIcon
                        icon={faPhone}
                        flip="horizontal"
                        size="2x"
                        color="#ed7d31ff"
                      />{' '}
                    </td>
                    <td>
                      <div className="links">
                        <h5>
                          {/* eslint-disable-next-line */}
                          <a
                            onClick={handleModalOpen}
                            style={{ cursor: 'pointer' }}
                          >
                            Click here to talk to a CDF representative today
                          </a>
                        </h5>
                        {
                          <ModalForm
                            modalOpen={modalOpen}
                            handleModalOpen={handleModalOpen}
                          />
                        }
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Home;

import merge from 'lodash/merge';
import { RedmineSettings } from './redmine-settings';

export const postRedmine = (issueSettings) => {
  const RedmineURL = RedmineSettings.redmineApiIssuesEndpoint;

  // tracker_id: 3='Support', 4='Consumer Onboarding', 5="Provider Onboarding"
  const issueData = JSON.stringify(
    merge(
      {
        issue: {
          is_private: '0',
          project_id:
            sessionStorage.getItem('redmineProjectId') ||
            window.PCT_ENVIRONMENT.redmineProjectId,
          tracker_id: '4',
          subject: '',
          description: '',
          status_id: '1',
          priority_id: '1',
        },
      },
      issueSettings,
    ),
  );
  console.log('postRedmine issueData = ', issueData);

  let myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  // Redmine API Key is injected via proxy server
  //myHeaders.append('X-Redmine-API-Key', redmineApiKey);

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: issueData,
    redirect: 'follow',
  };

  return fetch(RedmineURL, requestOptions);
};

// Expose postRedmine for testing via browser console
window.postRedmine = postRedmine;

import React, { useContext, useEffect } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import PCTAlertContext from './PCTAlertContext.js';

function PCTAlert() {
  const [alert, setAlert] = useContext(PCTAlertContext);
  const { show, variant, heading, text, info, buttons } = alert;

  const location = useLocation();

  useEffect(() => {
    if (show) {
      handleClose();
    }
  }, [location]);

  const renderButtons = () => {
    return buttons.map((button, index) => {
      return (
        <Button
          key={index}
          onClick={() => {
            button.onClick();
            handleClose();
          }}
          variant={button.variant}
        >
          {button.label}
        </Button>
      );
    });
  };

  const checkTextForHelpEmail = () => {
    const helpDeskRegex = /({{pctHelpDeskEmail}})/g;
    const email = sessionStorage.getItem('pctHelpDeskEmail');

    if (text.includes('{{pctHelpDeskEmail}}')) {
      const parts = text.split(helpDeskRegex);
      const elements = parts.map((part, i) => {
        if (part === '{{pctHelpDeskEmail}}') {
          return (
            <Alert.Link key={i} href={`mailto:${email}`}>
              {email}
            </Alert.Link>
          );
        } else {
          return <span key={i}>{part}</span>;
        }
      });
      return elements;
    }
    return text;
  };

  const handleClose = () => {
    setAlert({ ...alert, show: false });
  };

  return (
    <Alert show={show} variant={variant}>
      <Alert.Heading>{heading}</Alert.Heading>
      <p>{checkTextForHelpEmail()}</p>
      <p>{info}</p>
      <hr />
      <div className="d-flex justify-content-end">
        {renderButtons()}
        <Button onClick={handleClose} variant={'outline-' + variant}>
          Close
        </Button>
      </div>
    </Alert>
  );
}

export default PCTAlert;

import React from 'react';

const CDFhow = () => {
  return (
    <div>
      <h4>
        <img
          src="images/cdf-waffle.svg"
          style={{ display: 'inline-block', width: '50px' }}
          alt=""
        />
        &nbsp;How Does CDF Work?
      </h4>
      <table cellPadding="10px">
        <tbody>
          <tr>
            <td style={{ width: '450px', paddingRight: '30px' }}>
              <ul>
                <li>
                  {/* Brokers data via machine-to-machine interface */}
                  Automatically brokers data between systems with an ATO via
                  machine-to-machine interfaces
                </li>
                <li>
                  {/* Operates using a Commercial-Off-The-Shelf (COTS) platform,
                  with open-source components and limited custom software */}
                  Uses Data Sharing Agreements (DSAs) provided by onboarded
                  systems to create a digital access token
                </li>
                <li>
                  {/* Installed on any cloud or on-prem host environment */}
                  CDF Franchises can be installed on any cloud or on-prem host
                  environment
                </li>
              </ul>
            </td>
            <td>
              <img src="images/cdf-how.png" style={{ width: '75%' }} alt="" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
export default CDFhow;

import React, { useState } from 'react';
import { Nav, Tab, Row, Col } from 'react-bootstrap';
import CDFwhat from './static-pages/CDFwhat';
import CDFwhy from './static-pages/CDFwhy';
import CDFtoDIE from './static-pages/CDFtoDIE';
import CDFtoISR from './static-pages/CDFtoISR';
import CDFhow from './static-pages/CDFhow';
import CDFhowDataPolicy from './static-pages/CDFhowDataPolicy';
import CDFIOG from './static-pages/CDFIOG';

const Learn = () => {
  const tabs = {
    CDFwhat: {
      title: 'What is CDF',
      content: <CDFwhat />,
    },
    CDFwhy: {
      title: 'Why is CDF Necessary',
      content: <CDFwhy />,
    },
    CDFtoDIE: {
      title: 'CDF Support to DIE',
      content: <CDFtoDIE />,
    },
    CDFtoISR: {
      title: 'CDF Support to ISR',
      content: <CDFtoISR />,
    },
    CDFhow: {
      title: 'How Does CDF Work',
      content: <CDFhow />,
    },
    CDFdatapolicy: {
      title: 'How Does CDF Use Data Policy',
      content: <CDFhowDataPolicy />,
    },
    CDFIOG: {
      title: 'CDF Integrated Operations Guide',
      content: <CDFIOG />,
    },
  };

  const [activeTab, setActiveTab] = useState('CDFwhat');

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <div id="pctLearn">
      <Tab.Container id="left-tabs-example" defaultActiveKey={activeTab}>
        <Row>
          <Col lg={3}>
            <Nav id="verticalNavLearn" className="flex-column tab-style">
              {Object.entries(tabs).map((tab) => (
                <Nav.Item key={tab[0]}>
                  <Nav.Link
                    eventKey={tab[0]}
                    className={activeTab === tab[0] ? 'active' : ''}
                    onClick={() => {
                      toggle(tab[0]);
                    }}
                    role="button"
                  >
                    {tab[1].title}
                  </Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
          </Col>
          <Col lg={9}>
            <Tab.Content active={activeTab}>
              {Object.entries(tabs).map((tab) => (
                <Tab.Pane eventKey={tab[0]} key={tab[0]} id={tab[0]}>
                  {tab[1].content}
                </Tab.Pane>
              ))}
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </div>
  );
};

export default Learn;

export const RedmineSettings = {
  ticketSettings: {
    consumer: {
      tracker_id: '6',
      tracker_name: 'Consumer Onboarding Request and DSA Generated',
      subject:
        'Consumer Onboarding Request and DSA generated for ${jsonData2[regType + "_porMajorSystem"]} on ${sessionStorage.networkLabel}',
    },
    providerInitial: {
      tracker_id: '9',
      tracker_name: 'Provider Discovery Request',
      subject: '',
    },
    providerReturn: {
      tracker_id: '10',
      tracker_name: 'Provider DSA Generated',
      subject:
        'Provider DSA generated for ${jsonData2[regType + "_porMajorSystem"]} on ${sessionStorage.networkLabel}',
    },
  },
  /* eslint-disable indent */
  templates: {
    demographics: {
      consumer: {
        issue: (p) => ({
          tracker_id: p.trackerId,
          subject: p.subject,
          description: `${p.regType} Onboarding:
            Representative Name: ${p.jsonData2[p.regType + '_repName']}
            Representative Title: ${p.jsonData2[p.regType + '_repTitle']}
            Representative Email: ${p.jsonData2[p.regType + '_repEmail']}
            Representative Phone: ${p.jsonData2[p.regType + '_repPhone']}
            Representative Office Designation: ${
              p.jsonData2[p.regType + '_repOfficeDesignation']
            }
            Technical POC: ${p.jsonData2[p.regType + '_techPocName']}
            Technical POC Email: ${p.jsonData2[p.regType + '_techPocEmail']}
            Technical POC Phone: ${p.jsonData2[p.regType + '_techPocPhone']}
            Technical POC Group Email: ${
              p.jsonData2[p.regType + '_techPocGroupEmail']
            }
            Organization/Agency: ${p.jsonData2[p.regType + '_organization']}
            Network: ${p.jsonData2['networkLabel']}
            Program of Record: ${p.jsonData2[p.regType + '_porMajorSystem']}
            System: ${p.jsonData2[p.regType + '_systemName']}`,
          custom_fields: [
            {
              id: p.RS.redmineCustomFields['Representative Name'].id,
              value: p.jsonData2[p.regType + '_repName'],
            },
            {
              id: p.RS.redmineCustomFields['Representative Title'].id,
              value: p.jsonData2[p.regType + '_repTitle'],
            },
            {
              id: p.RS.redmineCustomFields['Representative Email'].id,
              value: p.jsonData2[p.regType + '_repEmail'],
            },
            {
              id: p.RS.redmineCustomFields['Representative Phone'].id,
              value: p.jsonData2[p.regType + '_repPhone'],
            },
            {
              id: p.RS.redmineCustomFields['Rep Office Designation'].id,
              value: p.jsonData2[p.regType + '_repOfficeDesignation'],
            },
            {
              id: p.RS.redmineCustomFields['Technical POC Name'].id,
              value: p.jsonData2[p.regType + '_techPocName'],
            },
            {
              id: p.RS.redmineCustomFields['Technical POC Email'].id,
              value: p.jsonData2[p.regType + '_techPocEmail'],
            },
            {
              id: p.RS.redmineCustomFields['Technical POC Phone'].id,
              value: p.jsonData2[p.regType + '_techPocPhone'],
            },
            {
              id: p.RS.redmineCustomFields['Distribution Email'].id,
              value: p.jsonData2[p.regType + '_techPocGroupEmail'],
            },
            {
              id: p.RS.redmineCustomFields['Organization/Agency'].id,
              value: p.jsonData2[p.regType + '_organization'],
            },
            {
              id: p.RS.redmineCustomFields['Network'].id,
              value: p.jsonData2['networkLabel'],
            },
            {
              id: p.RS.redmineCustomFields['Program of Record'].id,
              value: p.jsonData2[p.regType + '_porMajorSystem'],
            },
            {
              id: p.RS.redmineCustomFields['System Name'].id,
              value: p.jsonData2[p.regType + '_systemName'],
            },
            {
              id: p.RS.redmineCustomFields['Dataset Name'].id,
              value: p.jsonData2[p.regType + '_dataResourceName'],
            },
            {
              id: p.RS.redmineCustomFields['Additional Info'].id,
              value: p.jsonData2[p.regType + '_additionalInfo'],
            },
            {
              id: p.RS.redmineCustomFields['TestMode'].id,
              value:
                p.jsonData2[p.regType + '_testMode'] === 'sendTest'
                  ? 'Test'
                  : 'Actual',
            },
          ],
        }),
      },
      provider: {
        issue: (p) => ({
          tracker_id: p.trackerId,
          subject: p.subject,
          description: `${p.regType} Onboarding:
            Representative Name: ${p.jsonData2[p.regType + '_repName']}
            Representative Title: ${p.jsonData2[p.regType + '_repTitle']}
            Representative Email: ${p.jsonData2[p.regType + '_repEmail']}
            Representative Phone: ${p.jsonData2[p.regType + '_repPhone']}
            Representative Office Designation: ${
              p.jsonData2[p.regType + '_repOfficeDesignation']
            }
            Technical POC: ${p.jsonData2[p.regType + '_techPocName']}
            Technical POC Email: ${p.jsonData2[p.regType + '_techPocEmail']}
            Technical POC Phone: ${p.jsonData2[p.regType + '_techPocPhone']}
            Technical POC Group Email: ${
              p.jsonData2[p.regType + '_techPocGroupEmail']
            }
            Organization/Agency: ${p.jsonData2[p.regType + '_organization']}
            Network: ${p.jsonData2['networkLabel']}
            Program of Record: ${p.jsonData2[p.regType + '_porMajorSystem']}
            System: ${p.jsonData2[p.regType + '_systemName']}`,
          custom_fields: [
            {
              id: p.RS.redmineCustomFields['Representative Name'].id,
              value: p.jsonData2[p.regType + '_repName'],
            },
            {
              id: p.RS.redmineCustomFields['Representative Title'].id,
              value: p.jsonData2[p.regType + '_repTitle'],
            },
            {
              id: p.RS.redmineCustomFields['Representative Email'].id,
              value: p.jsonData2[p.regType + '_repEmail'],
            },
            {
              id: p.RS.redmineCustomFields['Representative Phone'].id,
              value: p.jsonData2[p.regType + '_repPhone'],
            },
            {
              id: p.RS.redmineCustomFields['Rep Office Designation'].id,
              value: p.jsonData2[p.regType + '_repOfficeDesignation'],
            },
            {
              id: p.RS.redmineCustomFields['Technical POC Name'].id,
              value: p.jsonData2[p.regType + '_techPocName'],
            },
            {
              id: p.RS.redmineCustomFields['Technical POC Email'].id,
              value: p.jsonData2[p.regType + '_techPocEmail'],
            },
            {
              id: p.RS.redmineCustomFields['Technical POC Phone'].id,
              value: p.jsonData2[p.regType + '_techPocPhone'],
            },
            {
              id: p.RS.redmineCustomFields['Distribution Email'].id,
              value: p.jsonData2[p.regType + '_techPocGroupEmail'],
            },
            {
              id: p.RS.redmineCustomFields['Organization/Agency'].id,
              value: p.jsonData2[p.regType + '_organization'],
            },
            {
              id: p.RS.redmineCustomFields['Network'].id,
              value: p.jsonData2['networkLabel'],
            },
            {
              id: p.RS.redmineCustomFields['Program of Record'].id,
              value: p.jsonData2[p.regType + '_porMajorSystem'],
            },
            {
              id: p.RS.redmineCustomFields['System Name'].id,
              value: p.jsonData2[p.regType + '_systemName'],
            },
            {
              id: p.RS.redmineCustomFields['Dataset Name'].id,
              value: p.jsonData2[p.regType + '_dataResourceName'],
            },
            {
              id: p.RS.redmineCustomFields['Additional Info'].id,
              value: p.jsonData2[p.regType + '_additionalInfo'],
            },
            {
              id: p.RS.redmineCustomFields['TestMode'].id,
              value:
                p.jsonData2[p.regType + '_testMode'] === 'sendTest'
                  ? 'Test'
                  : 'Actual',
            },
          ],
        }),
      },
    },
    interview: {
      issue: (p) => ({
        tracker_id: p.trackerId,
        subject: p.subject,
        description: `${p.regType} Onboarding:
          Representative Name: ${p.jsonData2[p.regType + '_repName']}
          Representative Title: ${p.jsonData2[p.regType + '_repTitle']}
          Representative Email: ${p.jsonData2[p.regType + '_repEmail']}
          Representative Phone: ${p.jsonData2[p.regType + '_repPhone']}
          Representative Office Designation: ${
            p.jsonData2[p.regType + '_repOfficeDesignation']
          }
          Technical POC: ${p.jsonData2[p.regType + '_techPocName']}
          Technical POC Email: ${p.jsonData2[p.regType + '_techPocEmail']}
          Technical POC Phone: ${p.jsonData2[p.regType + '_techPocPhone']}
          Technical POC Group Email: ${
            p.jsonData2[p.regType + '_techPocGroupEmail']
          }
          Organization/Agency: ${p.jsonData2[p.regType + '_organization']}
          Network: ${p.jsonData2['networkLabel']}
          Program of Record: ${p.jsonData2[p.regType + '_porMajorSystem']}
          System: ${p.jsonData2[p.regType + '_systemName']}
          Data Resource Name: ${p.jsonData2[p.regType + '_dataResourceName']}`,
        custom_fields: [
          {
            id: p.RS.redmineCustomFields['Representative Name'].id,
            value: p.jsonData2[p.regType + '_repName'],
          },
          {
            id: p.RS.redmineCustomFields['Representative Title'].id,
            value: p.jsonData2[p.regType + '_repTitle'],
          },
          {
            id: p.RS.redmineCustomFields['Representative Email'].id,
            value: p.jsonData2[p.regType + '_repEmail'],
          },
          {
            id: p.RS.redmineCustomFields['Representative Phone'].id,
            value: p.jsonData2[p.regType + '_repPhone'],
          },
          {
            id: p.RS.redmineCustomFields['Rep Office Designation'].id,
            value: p.jsonData2[p.regType + '_repOfficeDesignation'],
          },
          {
            id: p.RS.redmineCustomFields['Technical POC Name'].id,
            value: p.jsonData2[p.regType + '_techPocName'],
          },
          {
            id: p.RS.redmineCustomFields['Technical POC Email'].id,
            value: p.jsonData2[p.regType + '_techPocEmail'],
          },
          {
            id: p.RS.redmineCustomFields['Technical POC Phone'].id,
            value: p.jsonData2[p.regType + '_techPocPhone'],
          },
          {
            id: p.RS.redmineCustomFields['Distribution Email'].id,
            value: p.jsonData2[p.regType + '_techPocGroupEmail'],
          },
          {
            id: p.RS.redmineCustomFields['Organization/Agency'].id,
            value: p.jsonData2[p.regType + '_organization'],
          },
          {
            id: p.RS.redmineCustomFields['Network'].id,
            value: p.jsonData2['networkLabel'],
          },
          {
            id: p.RS.redmineCustomFields['Program of Record'].id,
            value: p.jsonData2[p.regType + '_porMajorSystem'],
          },
          {
            id: p.RS.redmineCustomFields['System Name'].id,
            value: p.jsonData2[p.regType + '_systemName'],
          },
          {
            id: p.RS.redmineCustomFields['Dataset Name'].id,
            value: p.jsonData2[p.regType + '_dataResourceName'],
          },
          {
            id: p.RS.redmineCustomFields['Additional Info'].id,
            value: p.jsonData2[p.regType + '_additionalInfo'],
          },
          {
            id: p.RS.redmineCustomFields['Policies Enforced'].id,
            value: p.jsonData2['policiesEnforced'],
          },
          {
            id: p.RS.redmineCustomFields['JSON Data Dump'].id,
            value: JSON.stringify(p.jsonData2, null, 2),
          },
          {
            id: p.RS.redmineCustomFields['TestMode'].id,
            value:
              p.jsonData2[p.regType + '_testMode'] === 'sendTest'
                ? 'Test'
                : 'Actual',
          },
        ],
      }),
    },
  },
  /* eslint-enable indent */
  redmineApiIssuesEndpoint: '/redmine/issues.json',
  redmineApiKey: {
    key: 'not-using-this--overridden-on-proxy-server',
    username: 'pct-app',
    server: 'tne-prod',
  },
  redmineDemographicsTracker: {
    consumer: {
      id: '6',
      name: 'Consumer Onboard Request',
    },
    provider: {
      id: '9',
      name: 'Provider Discovery Request',
    },
  },
  redmineInterviewTracker: {
    consumer: {
      id: '7',
      name: 'Consumer DSA Submission',
    },
    provider: {
      id: '10',
      name: 'Provider DSA Submission',
    },
  },
  redmineHelpTracker: {
    id: '11',
    name: 'PCT Help Request',
  },
  redmineCustomFields: {
    'Dataset Name': {
      id: 1,
      type: 'Text',
    },
    'Related Issues': {
      id: 2,
      type: 'Integer',
    },
    'Program of Record': {
      id: 3,
      type: 'Text',
    },
    'Network List': {
      id: 4,
      type: 'Key/value list',
      enumerations: ['T&E', 'SIPRNet', 'JWICS'],
    },
    'System Name': {
      id: 5,
      type: 'Text',
    },
    'Organization/Agency': {
      id: 6,
      type: 'Text',
    },
    'Product Name': {
      id: 7,
      type: 'Text',
    },
    'Catalog Description': {
      id: 8,
      type: 'Text',
    },
    'Customer Emails': {
      id: 9,
      type: 'Long text',
    },
    'Technical POC Email': {
      id: 16,
      type: 'Text',
    },
    'Technical POC Phone': {
      id: 17,
      type: 'Text',
    },
    'Distribution Email': {
      id: 18,
      type: 'Text',
    },
    'Representative Name': {
      id: 20,
      type: 'Text',
    },
    'Representative Phone': {
      id: 21,
      type: 'Text',
    },
    'Representative Email': {
      id: 22,
      type: 'Text',
    },
    Message: {
      id: 23,
      type: 'Long text',
    },
    'TestMode List': {
      id: 24,
      type: 'Key/value list',
      enumerations: ['Test', 'Actual'],
    },
    'Registration Type': {
      id: 26,
      type: 'Key/value list',
      enumerations: ['consumer', 'provider'],
    },
    'OpenProject Ticket Number': {
      id: 30,
      type: 'Text',
    },
    'Policies Enforced (Deprecated)': {
      id: 31,
      type: 'Text',
    },
    'JWICS Data Transfer URL': {
      id: 32,
      type: 'Text',
    },
    'DSA 2-Signature Complete Date': {
      id: 33,
      type: 'Text',
    },
    'DSA Delivery Method': {
      id: 34,
      type: 'Text',
    },
    'Representative Title': {
      id: 35,
      type: 'Text',
    },
    'Rep Office Designation': {
      id: 36,
      type: 'Text',
    },
    'Technical POC Name': {
      id: 37,
      type: 'Text',
    },
    Network: {
      id: 38,
      type: 'Text',
    },
    TestMode: {
      id: 39,
      type: 'Text',
    },
    'Additional Info': {
      id: 40,
      type: 'Long text',
    },
    'Policies Enforced': {
      id: 41,
      type: 'Long text',
    },
    'JSON Data Dump': {
      id: 42,
      type: 'Long text',
    },
    'Help Request Category': {
      id: 43,
      type: 'Text',
    },
  },
};

import React, { useState, useContext } from 'react';
import { Formio } from 'formiojs';
import { useHistory } from 'react-router-dom';
import TT4PNavBar from './TT4PNavBar';
import { saveLocalStorage } from '../policy-utils.js';
import { postRedmine } from '../redmine-utils.js';
import { localStorageWrap, uc1 } from '../global-utils';
import { loadResource, getRegistrationType } from '../resourceLoader.js';
import { Settings } from '../settings.js';
import { RedmineSettings } from '../redmine-settings.js';
import PCTAlertContext from './PCTAlertContext.js';
import OptionsMenuContext from './OptionsMenuContext.js';
import PCTAlert from './PCTAlert.js';

// alias
const S = Settings;
const RS = RedmineSettings;

const TT4PInfo = () => {
  const history = useHistory();

  // DETERMINE PAGE MODE
  // (TODO - Is there a more react-way to handle this?)
  // Render page using the following rules:
  // 1) if window.location.pathname contains registration type, then use it
  // 2) else use localStorage registrationType
  // 3) else use defaultRegistrationType
  const pathname = window.location.pathname;
  const registrationTypeOverride = pathname.match(/(consumer|provider)$/g)
    ? pathname.match(/(consumer|provider)$/g).toString()
    : null;

  // FIRST, load localStorage session data
  // const session = loadSession(registrationTypeOverride);
  const regType = getRegistrationType(registrationTypeOverride);
  const localStorageName = 'currentDataInfo';

  // SECOND, load JSON resources
  // TODO - we only need registrationType
  const resources = loadResource(regType);

  const [, setAlert] = useContext(PCTAlertContext);

  const [isQuickFilled, setQuickFill] = useState(false);
  const [hasClearedForm, setFormCleared] = useState(false);

  const [, setOptionsMenu] = useContext(OptionsMenuContext);

  const setIsQuickFill = () => {
    setQuickFill((isQuickFilled) => !isQuickFilled);
  };

  const setHasClearedForm = () => {
    setFormCleared((hasClearedForm) => !hasClearedForm);
  };

  const options = [
    {
      type: 'button',
      btnID: 'quickFillBtn',
      btnLabel: 'Fill Form Fields',
      btnToolTip: 'Fills in form with test data.',
      btnOnClick: setIsQuickFill,
    },
    {
      type: 'button',
      btnID: 'clearFormBtn',
      btnLabel: 'Clear Form Fields',
      // eslint-disable-next-line
      btnToolTip: "Clears all data entered in form's fields.",
      btnOnClick: setHasClearedForm,
    },
  ];

  // LOAD FORM
  const demographicsJsonPath = resources.demographicsJsonUrl;
  const fetchJson = async (path) => {
    console.log('DEBUG tt4pinfo - fetch path = ', path);
    const timestamp =
      Math.floor(new Date().getTime() / (30 * 60 * 1000)) * (30 * 60 * 1000);
    const response = await fetch(path + '?cb=' + timestamp);
    return await response.json();
  };

  React.useEffect(() => {
    (async () => {
      const formData = await fetchJson(demographicsJsonPath);
      // Expose formData to Javascript console
      window.formData = formData;

      Formio.icons = 'fontawesome';
      Formio.createForm(document.getElementById('formio'), formData, {
        //saveDraft: true, //TODO - Error "Cannot restore draft because there is no formio instance"
        hooks: {
          beforeSubmit: (submission, next) => {
            // Don't save session variables to localStorage
            // WAIT DON'T DO - this is clearing session vars before tt4pinterview
            const submissionDataSanitized = submission.data;
            // Object.keys(S.sessionKeys).forEach(
            //   (key) => delete submissionDataSanitized[key],
            // );
            // // TODO - improved dynamic method to reset session vars w/o using setting.js
            // for (const key in submissionDataSanitized) {
            //   if (key.startsWith('s_')) {
            //     delete submissionDataSanitized[key];
            //   }
            // }
            localStorage.setItem(
              localStorageName,
              JSON.stringify(submissionDataSanitized),
            );

            //Create Redmine issue
            const visitPurpose = submission.data.provider_visitPurpose;

            // Only Provider firstVisit creates a Redmine ticket here
            if (
              regType === 'provider' &&
              visitPurpose === 'firstVisit' &&
              sessionStorage.getItem('sendNotification') === 'send'
            ) {
              const trackerId =
                RedmineSettings.redmineDemographicsTracker.provider.id;
              const subject = sessionStorage.getItem('redmineSubject');
              const rmIssue = RedmineSettings.templates.demographics[
                regType
              ].issue({
                RS,
                //session,
                regType,
                trackerId,
                //testOnly,
                jsonData2: submission.data,
                uc1,
                subject,
              });

              postRedmine({
                issue: rmIssue,
              })
                .then((response) => {
                  if (!response.ok) {
                    console.log(
                      `DEBUG - Redmine error: '${response.status}': '${response.statusText}'`,
                    );
                    if (response.status) {
                      throw new Error(
                        sessionStorage.getItem(
                          'providerInitialRegistrationSubmitError',
                        ),
                      );
                    }
                  }
                  console.log('DEBUG - Redmine then response', response);
                  return response.json();
                })
                .then((data) => {
                  // Handle successful response
                  console.log('DEBUG - Redmine then result', data);
                  next();
                })
                .catch((error) => {
                  // Handle error
                  console.log(`DEBUG - Redmine error: '${error}'`);

                  setAlert({
                    show: true,
                    variant: 'danger',
                    heading: 'Error',
                    text: error.message,
                    buttons: [],
                    onClose: () => {},
                  });
                  window.scrollTo(0, 0);
                });
            } else {
              next();
            }
          },
          setDataValueUNCOMMENT2DEBUG: (value, key, data) => {
            // Remove "UNCOMMENT2DEBUG" suffix from key name to monitor formio initialization
            console.log(
              `DEBUG HOOK1 setDataValue: key=${key}, value=${value}, data=${JSON.stringify(
                data,
                null,
                2,
              )}`,
            );
            return value;
          },
        },
      }).then((form) => {
        // Expose form object to window environment
        console.log('DEBUG FORMIO.then...');
        window.formObj = form;
        // Restore saved form data
        let jsonData = localStorageWrap.getItem(localStorageName) || {};

        // Remove session keys from form data
        for (let sessionKey of Object.keys(S.sessionKeys)) {
          // TODO - prevent saving session keys in localStorage
          if (Object.prototype.hasOwnProperty.call(jsonData, sessionKey)) {
            // TODO - Deprecate this - We shouldn't find session keys in localStorage
            //        HOWEVER, we must remove session keys from form data at this point
            delete jsonData[sessionKey];
          }
        }
        console.log('DEBUG jsonData = ', jsonData);
        if (jsonData) {
          form.submission = { data: jsonData };
        }

        // Override "Submit form" button
        form.on('demographicsSave', function () {
          form.submit().then(function () {
            form.onChange();
            window.scrollTo(0, 0);
          });
        });
        // form.on('submitDone', function () {
        //   form.setAlert('success', 'Submission Complete', {
        //     persistent: true,
        //     classes: '',
        //   });
        // });
        // form.on('submitError', function () {
        //   form.setAlert('danger', 'Submission Error', {
        //     persistent: true,
        //     classes: '',
        //   });
        // });

        // Form Submit Handler
        form.on('submit', (jsonAnswers) => {
          console.log('Form was submitted', jsonAnswers);
          console.log(
            'DEBUG tt4pinfo FIRST LINE of submit handler - sessionStorage = ',
            sessionStorage,
          );

          // DEVELOPER NOTE
          // You will encounter the following error message on form "submit"
          // when you have an Object dot-notation chain for which a key
          // in the dot-chain cannot be resolved:
          //    Please fix the following errors before submitting.
          //    Cannot read properties of undefined (reading <key or variable>)

          // TODO - this is not the way to set the session variable (shouldn't be able to mutate here)
          saveLocalStorage(localStorageName, jsonAnswers, jsonData);

          // Remove 'active' from demographics stepperNav
          document.querySelector('a[href="/tt4pinfo"]').className = 'nav-link';

          //Create Redmine issue
          const visitPurpose = jsonAnswers.data.provider_visitPurpose;

          // Redirect to generate Provider DSA if Provider user is a Returning User,
          //   or if user is a Consumer.
          // TODO - Move logic to Registration form
          if (
            (regType === 'provider' &&
              (visitPurpose === 'returnVisit' ||
                visitPurpose === 'dsaRenewal')) ||
            regType === 'consumer'
          ) {
            history.push('/tt4pinterview');
            console.log(
              'DEBUG tt4pinfo LAST LINE of submit handler - sessionStorage = ',
              sessionStorage,
            );
          }
        });

        // Form Change Handler
        form.on('change', (jsonAnswers) => {
          console.log('Form was changed', jsonAnswers);

          // Any change to form makes it 'dirty'
          if (
            // Skip page initialization changes
            // eslint-disable-next-line
            jsonAnswers.changed !== 'undefined' &&
            jsonAnswers.changed?.flags.changes?.length <= 1 &&
            false // There are (currently) no changes on Registration form that can make 'dirty'
          ) {
            // console.log('DEBUG - TT4Pinfo.js - set DIRTY');
            sessionStorage.setItem('dirtyCheck', 'dirty');
            document.querySelector(
              'a[href="/tt4pinterview"]',
            ).style.pointerEvents = 'none';
          }
          // Save answers to prevent original answers are being lost upon first "change" triggered via page load...
          // Update session.targetNetwork (can be incorrect if user changed registrationType w/o triggering a form change)
          // TODO - this is not the way to set the session variable (shouldn't be able to mutate here)
          if (!localStorageWrap.getItem(localStorageName)) {
            // Create localStorage if missing
            localStorage.setItem(localStorageName, '{}');
          }

          for (let sessionKey of Object.keys(S.sessionKeys)) {
            if (sessionKey == 'registrationType') {
              // Skip registrationType (only changes at page load)
              // Skip any other session keys dependent on registrationType?
              continue;
            }
            let sessionValue = jsonAnswers.data[sessionKey];
            console.log(
              `DEBUG tt4pinfo 'change' updating ${sessionKey} = ${sessionValue}`,
            );
            // IMPORTANT - save default & calculated form values to sessionStorage
            sessionStorage.setItem(sessionKey, sessionValue);
          }
          console.log(
            'DEBUG tt4pinfo.js form.change END - sessionStorage = ',
            JSON.stringify(sessionStorage, null, 2),
          );
        });

        form.on('prevPage', () => window.scrollTo(0, 0));
        form.on('nextPage', () => window.scrollTo(0, 0));

        if (isQuickFilled) {
          // TODO - do we need to reset form?...
          //        Causes registrationType to unset
          //        and other critical keys
          //        FIX SESSION LOADING
          //form.emit('resetForm');
          //form.resetValue();
          const filePath = sessionStorage.getItem('quickFillRegistrationFile');
          const timestamp =
            Math.floor(new Date().getTime() / (30 * 60 * 1000)) *
            (30 * 60 * 1000);
          const xhr = new XMLHttpRequest();
          xhr.open('GET', filePath + '?cb=' + timestamp, true);
          xhr.onreadystatechange = function () {
            if (xhr.readyState === XMLHttpRequest.DONE) {
              if (xhr.status === 200) {
                console.log(
                  'DEBUG QuickFill submissionData = ',
                  xhr.responseText,
                );
                const submissionData = JSON.parse(xhr.responseText);
                form.submission = submissionData;
                // Don't save session variables to localStorage
                const submissionDataSanitized = submissionData['data'];
                Object.keys(S.sessionKeys).forEach(
                  (key) => delete submissionDataSanitized[key],
                );
                // TODO - Refactor to eliminate dependency on settings.js
                // for (const key in submissionDataSanitized) {
                //   if (key.startsWith('s_')) {
                //     delete submissionDataSanitized[key];
                //   }
                // }
                localStorageWrap.setItem(
                  localStorageName,
                  submissionDataSanitized,
                );
                setQuickFill((isQuickFilled) => !isQuickFilled);
              } else {
                console.error(
                  'DEBUG tt4pinfo load QuickFill JSON Error: ',
                  xhr.status,
                );
              }
            }
          };
          xhr.send(null);
        }

        if (hasClearedForm) {
          localStorageWrap.setItem(localStorageName, {});
          form.resetValue();
          // emit->resetForm is clearing session
          //form.emit('resetForm');
          setFormCleared((hasClearedForm) => !hasClearedForm);
        }
      });
      Formio.setUser({
        _id: '123',
      });
      // Enable/Disable Step-2 link
      const tt4pInterviewNode = document.querySelector(
        'a[href="/tt4pinterview"]',
      );
      if (
        (tt4pInterviewNode && tt4pInterviewNode.classList.contains('active')) ||
        sessionStorage.getItem('dirtyCheck') === 'clean'
      ) {
        tt4pInterviewNode.style.pointerEvents = 'auto';
      } else {
        tt4pInterviewNode.style.pointerEvents = 'none';
      }
    })(); // end async
    // eslint-disable-next-line
  }, [hasClearedForm, isQuickFilled]);

  const pattern = [
    'ArrowUp',
    'ArrowUp',
    'ArrowDown',
    'ArrowDown',
    'ArrowLeft',
    'ArrowRight',
    'ArrowLeft',
    'ArrowRight',
    'b',
    'a',
  ];
  let current = 0;

  const keyHandler = function (event) {
    if (pattern.indexOf(event.key) < 0 || event.key !== pattern[current]) {
      current = 0;
      return;
    }

    current++;

    if (pattern.length === current) {
      current = 0;
      setOptionsMenu({
        options: options,
        showOptionsMenu: true,
      });
    }
  };

  return (
    <div
      id="pctDemographics"
      className="container-lg"
      onKeyDown={keyHandler}
      tabIndex="0"
    >
      <TT4PNavBar className="header-menu-container mx-0" />
      <PCTAlert />
      <div id="formio" className="mx-3 my-2"></div>
    </div>
  );
};

export default TT4PInfo;

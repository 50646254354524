import React, { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Button from 'react-bootstrap/Button';
import { createPDFGenerator, GeneratorTypes } from 'pdf-generator';
import './rbsDropdown.css';

export const InterviewPDFDropdown = () => {
  const [dropdownText, setDropdownText] = useState(
    'Select Policy Questionnaire',
  );
  const [filePath, setFilePath] = useState('');
  const [isDLButtonActive, setDlButtonActive] = useState(true);

  const interviewFiles = [
    {
      name: `${window.PCT_ENVIRONMENT.networkLabel} Consumer Interview`,
      filePath: '/configs/interview_previews/dl-interview-consumer.json',
    },
    {
      name: `${window.PCT_ENVIRONMENT.networkLabel} Provider Interview`,
      filePath: '/configs/interview_previews/dl-interview-provider.json',
    },
  ];

  const changeDropdownText = (text) => {
    setDropdownText(text);
  };

  const handleSelect = (e) => {
    changeDropdownText(e);
    setDlButtonActive(false);

    var arrayLength = interviewFiles.length;
    for (var i = 0; i < arrayLength; i++) {
      if (interviewFiles[i]['name'] === e) {
        setFilePath(interviewFiles[i]['filePath']);
      }
    }
  };

  return (
    <div className="ddContainer">
      <p className="containerText">Download Printable Questionnaire</p>
      <div className="dbContainer">
        <DropdownButton
          className="dropdownBtn"
          variant="light"
          id="dropdown-list-button"
          title={dropdownText}
          onSelect={handleSelect}
        >
          {interviewFiles.map((element, index) => (
            <Dropdown.Item key={index} eventKey={element.name}>
              {element.name}
            </Dropdown.Item>
          ))}
        </DropdownButton>
        <DownloadButton filePath={filePath} isDisabled={isDLButtonActive} />
      </div>
    </div>
  );
};

// eslint-disable-next-line
export const DownloadButton = ({ filePath, isDisabled }) => {
  const timestamp =
    Math.floor(new Date().getTime() / (30 * 60 * 1000)) * (30 * 60 * 1000);
  const handleClick = (filePath) => {
    async function fetchDLInterviewJson() {
      try {
        const response = await fetch(filePath + '?cb=' + timestamp);
        const dlInterviewTemplate = await response.json();

        const fontNames = dlInterviewTemplate.documentSettings.fonts;

        const loadFonts = async function () {
          try {
            const fontPromises = await Promise.all(
              fontNames.map((fontName) => fetch(`/fonts/${fontName}.ttf`)),
            );
            const fontResponses = await Promise.all(fontPromises);
            const bufferedFonts = await Promise.all(
              fontResponses.map(function (response) {
                return response.arrayBuffer();
              }),
            );

            let fonts = [];

            for (let i = 0; i < bufferedFonts.length; i++) {
              fonts.push({
                name: fontNames[i],
                buffer: bufferedFonts[i],
              });
            }

            const dlInterviewGenerator = createPDFGenerator({
              generator_type: GeneratorTypes.DL_INTERVIEW,
              template: dlInterviewTemplate,
              data: null,
              fonts: fonts,
            });

            dlInterviewGenerator.generateDLInterview();

            // On finish document stream code has to live outside of the generator
            dlInterviewGenerator.documentStream.on('finish', async function () {
              const dlInterviewPdfBlob =
                dlInterviewGenerator.documentStream.toBlob('application/pdf');

              const fileURL = window.URL.createObjectURL(dlInterviewPdfBlob);
              const desiredFileName = dlInterviewGenerator.getFileName();
              const link = document.createElement('a');
              link.href = fileURL;
              link.download = desiredFileName;
              link.click();
            });
          } catch (error) {
            console.log('Error loading fonts. ', error);
          }
        };
        loadFonts();
      } catch (error) {
        console.log('Error loading JSON template. ', error);
      }
    }

    fetchDLInterviewJson();
  };

  return (
    <div>
      <Button
        className="downloadBtn"
        onClick={() => handleClick(filePath)}
        disabled={isDisabled}
      >
        Download
      </Button>
    </div>
  );
};

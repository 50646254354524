import React, { useState, useEffect, useContext } from 'react';
import merge from 'lodash/merge';
import { getRegistrationType } from '../resourceLoader.js';
import { localStorageWrap, uc1 } from '../global-utils';
import { saveAs } from 'file-saver';
import { Settings } from '../settings.js';
import { createPDFGenerator, GeneratorTypes } from 'pdf-generator';
import '../App.css';
import PCTAlertContext from './PCTAlertContext.js';
import PCTAlert from './PCTAlert.js';
import OptionsMenuContext from './OptionsMenuContext.js';

const DSAGenerator = () => {
  const [url, setUrl] = useState('');
  const registrationType = getRegistrationType();
  const customer_type = uc1(registrationType);
  const dsaTitle = 'Data ' + customer_type;

  const [, setAlert] = useContext(PCTAlertContext);

  const [, setOptionsMenu] = useContext(OptionsMenuContext);

  const [useTestData, setUseTestData] = useState(false);

  const setTestData = () => {
    setUseTestData((useTestData) => !useTestData);
  };

  const options = [
    {
      type: 'button',
      btnID: 'injectDSATestData',
      btnLabel: 'Inject DSA Test Data',
      btnToolTip: 'Injects baseline test data.',
      btnOnClick: setTestData,
      isChecked: true,
    },
  ];

  function getDsaFileName(jsonDataSubmitted, registrationType, networkLabel) {
    // FORMAT = <system name> (<por major system>) Consumer|Provider DSA on <network>-timestamp.pdf
    const timestamp = new Date().toISOString().replace(/[:.]/g, '');
    const systemName =
      jsonDataSubmitted[`${registrationType}_systemName`] || 'SystemNameNotSet';
    const porMajorSystem =
      jsonDataSubmitted[`${registrationType}_porMajorSystem`] ||
      'PorMajorSystemNotSet';

    const dsaFilename = `${systemName.replace(
      /[ ]/g,
      '',
    )} (${porMajorSystem.replace(/[ ]/g, '')}) ${uc1(
      registrationType,
    )} DSA on ${networkLabel} - ${timestamp}.pdf`;
    return dsaFilename;
  }

  function setupDSA() {
    const data = merge(
      localStorageWrap.getItem('currentDataInterview'),
      localStorageWrap.getItem('currentDataInfo'),
    );

    data.registrationType = registrationType;

    data.network = sessionStorage.getItem('networkLabel');

    const timestamp =
      Math.floor(new Date().getTime() / (30 * 60 * 1000)) * (30 * 60 * 1000);

    let dsaTemplate =
      sessionStorage.getItem('dsaGenTemplate') ||
      '/configs/dsa_templates/missing_data_dsa_template.json';

    let dontSave = sessionStorage.getItem('dsaGenTemplate') === null;

    if (!data.consumer_repName && !data.provider_repName) {
      dsaTemplate = '/configs/dsa_templates/missing_data_dsa_template.json';
      dontSave = true;
    }

    dsaTemplate += `?cb=${timestamp}`;

    return [dsaTemplate, data, dontSave];
  }

  async function createDSA(dsaTemplate, data, dontSave) {
    fetch(dsaTemplate)
      .then((response) => response.json())
      .then((dsaTemplate) => {
        const fontNames = dsaTemplate.documentSettings.fonts;

        const fontPromises = fontNames.map((fontName) =>
          fetch(`/fonts/${fontName}.ttf`),
        );

        Promise.all(fontPromises)
          .then(function (responses) {
            return Promise.all(
              responses.map(function (response) {
                return response.arrayBuffer();
              }),
            );
          })
          .then(function (bufferedFonts) {
            let fonts = [];

            for (let i = 0; i < bufferedFonts.length; i++) {
              fonts.push({
                name: fontNames[i],
                buffer: bufferedFonts[i],
              });
            }

            const dsaGenerator = createPDFGenerator({
              generator_type: GeneratorTypes.DSA,
              template: dsaTemplate,
              data: data,
              fonts: fonts,
            });

            dsaGenerator.generateDSA();

            // On finish document stream code has to live outside of the dsa-generator
            dsaGenerator.documentStream.on('finish', function () {
              const dsaPdfBlob =
                dsaGenerator.documentStream.toBlob('application/pdf');

              setUrl(URL.createObjectURL(dsaPdfBlob));

              if (!dontSave) {
                saveAs(
                  dsaPdfBlob,
                  getDsaFileName(
                    data,
                    registrationType,
                    sessionStorage.getItem('networkLabel'),
                  ),
                );
              }

              setAlert({
                show: true,
                variant: 'success',
                heading: 'DSA Downloaded',
                text: Settings.messages.dsaAutoDownloaded,
                buttons: [],
                onClose: () => {},
              });
            });
          });
      });
  }

  useEffect(() => {
    // This condition must be executed at the start of this useEffect block.
    if (useTestData) {
      fetch(
        '/configs/baselines/baseline-dsa-test-data-' +
          registrationType +
          '.json',
      )
        .then((response) => response.json())
        .then((dsaTestData) => {
          Object.entries(dsaTestData.sessionKeys).forEach(([key, value]) => {
            sessionStorage.setItem(key, value);
          });

          localStorage.setItem(
            'currentDataInfo',
            JSON.stringify(dsaTestData.currentDataInfo),
          );

          localStorage.setItem(
            'currentDataInterview',
            JSON.stringify(dsaTestData.currentDataInterview),
          );

          const [dsaTemplate, data, dontSave] = setupDSA();

          createDSA(dsaTemplate, data, dontSave);
        });
    } else {
      const [dsaTemplate, data, dontSave] = setupDSA();

      createDSA(dsaTemplate, data, dontSave);
    }

    const tt4pInterviewNode = document.querySelector(
      'a[href="/tt4pinterview"]',
    );
    if (tt4pInterviewNode.classList.contains('active')) {
      tt4pInterviewNode.style.pointerEvents = 'auto';
    } else if (sessionStorage.getItem('dirtyCheck') === 'clean') {
      tt4pInterviewNode.style.pointerEvents = 'auto';
    } else {
      tt4pInterviewNode.style.pointerEvents = 'none';
    }

    // eslint-disable-next-line
  }, [useTestData]);

  const pattern = [
    'ArrowUp',
    'ArrowUp',
    'ArrowDown',
    'ArrowDown',
    'ArrowLeft',
    'ArrowRight',
    'ArrowLeft',
    'ArrowRight',
    'b',
    'a',
  ];
  let current = 0;

  const keyHandler = function (event) {
    if (pattern.indexOf(event.key) < 0 || event.key !== pattern[current]) {
      current = 0;
      return;
    }

    current++;

    if (pattern.length === current) {
      current = 0;
      setOptionsMenu({
        options: options,
        showOptionsMenu: true,
      });
    }
  };

  return (
    <div id="dsa" className="App" onKeyDown={keyHandler} tabIndex="0">
      <div id="dsaRegistrationType" className="header">
        <span className="registration-type">
          {dsaTitle} on {sessionStorage.getItem('networkLabel')}
        </span>{' '}
        - Data Sharing Agreement &nbsp;&nbsp;&nbsp;
      </div>
      <PCTAlert />
      <div id="pdfViewer" className="webviewer">
        <iframe
          src={url}
          width="100%"
          height="1000"
          name="dsaview"
          title="dsaview"
        ></iframe>
      </div>
    </div>
  );
};

export default DSAGenerator;

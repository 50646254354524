import React from 'react';
import { Formio } from 'formiojs';
import { useHistory } from 'react-router-dom';
import { getPolicyStr, getPolicyDiff } from '../policy-utils.js';
import { postRedmine } from '../redmine-utils.js';
import { localStorageWrap, uc1 } from '../global-utils.js';
import { getRegistrationType } from '../resourceLoader.js';
import { RedmineSettings } from '../redmine-settings.js';
import PolicyDiffViewer from './PolicyDiffViewer.js';
import merge from 'lodash/merge';
import { useState, useContext } from 'react';
import { ModalAlertContext } from './ModalAlertQueue.js';
import PCTAlertContext from './PCTAlertContext.js';
import OptionsMenuContext from './OptionsMenuContext.js';
import PCTAlert from './PCTAlert.js';

// alias
const RS = RedmineSettings;

const TT4PInterview = () => {
  const history = useHistory();

  const { showModalAlert } = useContext(ModalAlertContext);
  const [, setAlert] = useContext(PCTAlertContext);

  // FIRST, load localStorage session data
  const regType = getRegistrationType();
  const localStorageName = 'currentDataInterview'; //session.localStorageAnswersKey;

  const [isPolicyDiffPanelEnabled, setPolicyDiffPanelEnabled] = useState(
    sessionStorage.getItem('isPolicyDiffPanelEnabled') === 'true',
  );

  const [isQuickFilled, setQuickFill] = useState(false);
  const [hasClearedForm, setFormCleared] = useState(false);

  const [, setOptionsMenu] = useContext(OptionsMenuContext);

  const dsaGenerator = '/dsagenerator';

  const setIsPolicyDiffPanelEnabled = () => {
    sessionStorage.setItem(
      'isPolicyDiffPanelEnabled',
      !isPolicyDiffPanelEnabled,
    );

    setPolicyDiffPanelEnabled(
      (isPolicyDiffPanelEnabled) => !isPolicyDiffPanelEnabled,
    );
  };

  const setIsQuickFill = () => {
    setQuickFill((isQuickFilled) => !isQuickFilled);
  };

  const setHasClearedForm = () => {
    setFormCleared((hasClearedForm) => !hasClearedForm);
  };

  const options = [
    {
      type: 'switch',
      btnID: 'policyPanelBtn',
      btnLabel: 'Policy Panel',
      btnToolTip: 'Displays the policy panel.',
      btnOnClick: setIsPolicyDiffPanelEnabled,
      isChecked: isPolicyDiffPanelEnabled,
    },
    {
      type: 'button',
      btnID: 'quickFillBtn',
      btnLabel: 'Fill Form Fields',
      btnToolTip: 'Fills in form with test data.',
      btnOnClick: setIsQuickFill,
    },
    {
      type: 'button',
      btnID: 'clearFormBtn',
      btnLabel: 'Clear Form Field',
      // eslint-disable-next-line
      btnToolTip: "Clears all data entered in form's fields.",
      btnOnClick: setHasClearedForm,
    },
  ];

  const interviewJsonPath =
    sessionStorage.getItem('interviewFile') ||
    '/configs/forms/formio-interview-missing.json';
  const policiesJsonPath =
    sessionStorage.getItem('policiesFile') ||
    '/configs/forms/qa-policies-missing.json';

  const fetchJson = async (path) => {
    console.log('DEBUG tt4pinterview - fetch path = ', path);
    const timestamp =
      Math.floor(new Date().getTime() / (30 * 60 * 1000)) * (30 * 60 * 1000);
    const response = await fetch(path + '?cb=' + timestamp);
    return await response.json();
  };

  const pushHistory = () => {
    history.push(dsaGenerator);
  };

  React.useEffect(() => {
    (async () => {
      const formData = await fetchJson(interviewJsonPath);
      const policyList = await fetchJson(policiesJsonPath);
      // Expose formData & policyList to Javascript console
      window.formData = formData;
      window.policyList = policyList;

      Formio.icons = 'fontawesome';
      Formio.createForm(document.getElementById('formio'), formData, {
        hooks: {
          beforeSubmit: (submission, next) => {
            // Calculate Policies
            const policiesEnforced = getPolicyStr(
              policyList,
              submission.data,
              regType,
            );
            // Update submission
            submission.data['policiesEnforced'] = policiesEnforced;
            // Save submission
            localStorage.setItem(
              localStorageName,
              JSON.stringify(submission.data),
            );
            next();
          },
        },
      }).then((form) => {
        // THIS SECTION OF CODE IS THE "FORM CONTROLLER"
        // Expose form object to window environment
        window.formObj = form;
        // Restore saved form data
        let jsonData = localStorage.getItem(localStorageName);
        if (jsonData) {
          form.submission = { data: JSON.parse(jsonData) };
        }

        // Override "Submit form" button
        form.on('demographicsSave', function () {
          form.submit();
        });

        form.on('submit', (jsonAnswers) => {
          console.log('Form was submitted', jsonAnswers);

          // Submission of form makes it 'clean'
          sessionStorage.setItem('dirtyCheck', 'clean');
          document.querySelector(
            'a[href="/tt4pinterview"]',
          ).style.pointerEvents = 'auto';

          //Create Redmine issue
          // TODO - we should async this function and wait for
          //   confirmation that Redmine issue was successfully sent.
          const jsonData2 = merge(
            localStorageWrap.getItem(localStorageName),
            // TODO - get demographics localStorage key from settings
            localStorageWrap.getItem('currentDataInfo'),
            jsonAnswers.data,
          );

          // 'submissionSkipOrSendNotification'sq is set in Interview File
          const sendOrSkip = jsonAnswers.data.submissionSkipOrSendNotification;

          if (sendOrSkip === 'sendNotification') {
            const trackerId = sessionStorage.getItem('redmineTrackerId');
            const subject = sessionStorage.getItem('redmineSubject');

            const rmIssue = RedmineSettings.templates.interview.issue({
              RS,
              //session,
              regType,
              trackerId,
              jsonData2,
              uc1,
              subject,
            });

            postRedmine({
              issue: rmIssue,
            })
              .then((response) => {
                if (!response.ok) {
                  console.log(
                    `DEBUG - Redmine error: '${response.status}': '${response.statusText}'`,
                  );
                  if (response.status) {
                    throw new Error(
                      sessionStorage.getItem(`${regType}InterviewSubmitError`),
                    );
                  }
                }
                console.log('DEBUG - Redmine then response', response);
                return response.json();
              })
              .then((data) => {
                // Handle successful response
                console.log('DEBUG - Redmine then result', data);
                showModalAlert({
                  modalAlertBody: sessionStorage.getItem(
                    `${regType}InterviewSubmitSuccess`,
                  ),
                  modalAlertTitle: 'Submission Success',
                  buttons: [
                    {
                      label: 'OK',
                      variant: 'primary',
                    },
                  ],
                });
                // Only Redirect to generate DSA on success
                history.push(dsaGenerator);
              })
              .catch((error) => {
                // Handle error
                console.log(`DEBUG - Redmine error: '${error}'`);
                setAlert({
                  show: true,
                  variant: 'danger',
                  heading: 'Warning',
                  text: error.message,
                  info: sessionStorage.getItem(`${regType}DsaErrorInfo`),
                  buttons: [
                    {
                      label: 'Continue',
                      variant: 'outline-secondary',
                      onClick: () => {
                        pushHistory();
                      },
                    },
                  ],
                  onClose: () => {},
                });

                window.scrollTo(0, 0);

                // Hides the default formio submission alert
                form.setAlert('danger', '', {
                  persistent: false,
                  classes: '',
                });
              });
          } else {
            // No Redmine interaction require redirect to generate DSA
            history.push(dsaGenerator);
          }
        });

        form.on('change', (jsonAnswers) => {
          console.log('Form was changed', jsonAnswers);

          // Recalculate policiesEnforced
          // TODO: Find out a better way of preventing previously calculated policies
          //       from appearing in policy diff window after "Clear Form" is clicked in Options Menu.
          const calculatedPolicies = !hasClearedForm
            ? getPolicyStr(policyList, jsonAnswers.data, regType)
            : '';

          // Expose in global window namespace
          window.calculatedPolicies = calculatedPolicies;
          jsonAnswers.data['policiesEnforced'] = calculatedPolicies;

          if (isPolicyDiffPanelEnabled) {
            // Render PolicyDiff frame
            // TODO - abstract this block to separate function and call only when testMode==true
            // Get & store New policy list
            const policyListPrevious =
              localStorage.getItem('policyList') !== null
                ? localStorage.getItem('policyList')
                : '';

            // Save current policyList
            localStorage.setItem('policyList', calculatedPolicies);

            // HACK force set innerHTML
            const policyDiffed = getPolicyDiff(
              policyListPrevious,
              calculatedPolicies,
            );
            document.querySelector('#policyDiff').innerHTML = policyDiffed;
          }
          console.log(
            'DEBUG tt4pinterview.js form.change END - sessionStorage = ',
            JSON.stringify(sessionStorage, null, 2),
          );
        });

        form.on('prevPage', () => window.scrollTo(0, 0));
        form.on('nextPage', () => window.scrollTo(0, 0));

        if (isQuickFilled) {
          // TODO - do we need to reset form?...
          //        Causes registrationType to unset
          //form.emit('resetForm');
          // Load Baseline (QuickFill) Form Submission Data
          const filePath = sessionStorage.getItem('quickFillInterviewFile');
          const timestamp =
            Math.floor(new Date().getTime() / (30 * 60 * 1000)) *
            (30 * 60 * 1000);
          const xhr = new XMLHttpRequest();
          xhr.open('GET', filePath + '?cb=' + timestamp, true);
          xhr.onreadystatechange = function () {
            if (xhr.readyState === XMLHttpRequest.DONE) {
              if (xhr.status === 200) {
                console.log(
                  'DEBUG QuickFill submissionData = ',
                  xhr.responseText,
                );
                const submissionData = JSON.parse(xhr.responseText);
                form.submission = submissionData;
                localStorageWrap.setItem(
                  localStorageName,
                  submissionData['data'],
                );
                setQuickFill((isQuickFilled) => !isQuickFilled);
              } else {
                console.error(
                  'DEBUG tt4pinfo load QuickFill JSON Error: ',
                  xhr.status,
                );
              }
            }
          };
          xhr.send(null);
        }

        if (hasClearedForm) {
          localStorageWrap.setItem(localStorageName, {});
          form.resetValue();
          // emit('resetForm') is clearing session
          //form.emit('resetForm');
          setFormCleared((hasClearedForm) => !hasClearedForm);
        }
      });
      Formio.setUser({
        _id: '123',
      });
      const tt4pInterviewNode = document.querySelector(
        'a[href="/tt4pinterview"]',
      );
      if (
        (tt4pInterviewNode && tt4pInterviewNode.classList.contains('active')) ||
        sessionStorage.getItem('dirtyCheck') === 'clean'
      ) {
        tt4pInterviewNode.style.pointerEvents = 'auto';
      } else {
        tt4pInterviewNode.style.pointerEvents = 'none';
      }
    })(); // end async
    // eslint-disable-next-line
  }, [
    isPolicyDiffPanelEnabled,
    hasClearedForm,
    isQuickFilled,
    history,
    interviewJsonPath,
    policiesJsonPath,
    regType,
    dsaGenerator,
  ]);

  const pattern = [
    'ArrowUp',
    'ArrowUp',
    'ArrowDown',
    'ArrowDown',
    'ArrowLeft',
    'ArrowRight',
    'ArrowLeft',
    'ArrowRight',
    'b',
    'a',
  ];

  let current = 0;
  const keyHandler = function (event) {
    if (pattern.indexOf(event.key) < 0 || event.key !== pattern[current]) {
      current = 0;
      return;
    }

    current++;

    if (pattern.length === current) {
      current = 0;
      setOptionsMenu({
        options: options,
        showOptionsMenu: true,
      });
    }
  };
  // TODO: how to update this variable from form.on.render?
  const calculatedPolicies = '- This is policy one.\n- This is policy two.'; // TODO

  return (
    <div
      id="pctInterview"
      className="App row"
      onKeyDown={keyHandler}
      tabIndex="0"
    >
      <PCTAlert />
      <div id="formio" className="mt-1 col-lg-9"></div>
      {isPolicyDiffPanelEnabled ? (
        <div id="policyDiffPanel" className="col-lg-3">
          <p style={{ fontWeight: 'bold', color: 'GrayText' }}>
            Policies Attached
          </p>
          <PolicyDiffViewer
            id="policyDiffViewer"
            policyListNew={calculatedPolicies}
          />
          <hr />
          <p style={{ color: 'graytext', fontSize: 'smaller' }}>
            **Caveat
            <br />
            The &quot;Policies Attached&quot; list highlights ADDED policies
            (green) or REMOVED policies (red) based on selected answers. The
            highlighted change will sometimes appear split across multiple
            lines, but notice that the entire highlighted text does accurately
            comprise the full policy string that was added or removed.
          </p>
        </div>
      ) : null}
    </div>
  );
};

export default TT4PInterview;

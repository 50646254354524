import { createContext } from 'react';

const OptionsMenuContext = createContext({
  id: 'optionsMenu',
  options: [],
  showOptionsMenu: false,
  showAlert: false,
  alertMessage: '',
  handleAlertClose: () => {},
});

export default OptionsMenuContext;

export const Resources = {
  missing: {
    demographicsJsonUrl: '/configs/forms/formio-demographics-missing.json',
  },
  consumer: {
    demographicsJsonUrl: '/configs/forms/formio-demographics-consumer.json',
  },
  provider: {
    demographicsJsonUrl: '/configs/forms/formio-demographics-provider.json',
  },
};

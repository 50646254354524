export const upperCaseString = (str) => {
  const sStr = str.toString(); // ensure string, not array
  return sStr[0].toUpperCase() + sStr.slice(1);
};
// simpler alias
export const uc1 = upperCaseString;
window.uc1 = upperCaseString;

export const loadSessionVars = () => {
  return '';
};

export const localStorageWrap = {
  getItem: function (key) {
    return JSON.parse(localStorage.getItem(key));
  },
  setItem: function (key, value) {
    return localStorage.setItem(key, JSON.stringify(value));
  },
  removeItem: function (key) {
    return localStorage.removeItem(key);
  },
};

import React from 'react';

const CDFwhy = () => {
  return (
    <div>
      {/* According to Bill Bal's comments on the PPT, this is dated guidance. To be updated? */}
      <h4>
        <img
          src="images/cdf-waffle.svg"
          style={{ display: 'inline-block', width: '50px' }}
          alt=""
        />
        &nbsp;Why is the CDF Necessary?
      </h4>
      <p>
        IC DoD Data is not easily discoverable, accessible and retrievable
        across the DoD.
      </p>
      <p>
        Across the DoD, Service components and Agencies operate under unique
        titles, authorities, and missions, generating and consuming data in
        support of their missions. As a result, data gets created and stored in
        mission silos and becomes stove-piped and cannot be easily accessed from
        outside those mission boundaries and governing authorities.
      </p>
      <span>
        CDF is the first of its kind where data is brokered based on data policy
        <ul>
          <li>
            Enterprise Scope and Foundation for Achieving DIE Architectural
            Superiority
          </li>
          <li>Fundamentally Changes How Data is Delivered to the Warfighter</li>
          <li>A Single ATO Connection Enables Data Sharing Across All DoD</li>
          <li>Supports RMF Reciprocity Directive</li>
          <li>Redundant, Resilient &amp; Scalable</li>
        </ul>
      </span>
      <table cellPadding="10px">
        <tbody>
          <tr>
            <td className="textBlock">
              <span>
                “Being faster and better requires having unfettered access to
                the tasking, collection, processing, analysis, and dissemination
                of information derived from all available sources”
              </span>
              <div style={{ fontSize: '12pt' }}>
                <i>- Joint Intelligence, JP 2-0, 22 Oct 2013</i>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <p>{/* <img src="images/cdf-why.svg" /> */}</p>
    </div>
  );
};

export default CDFwhy;

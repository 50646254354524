import React, { useState } from 'react';

const Utility = () => {
  function toCamelCase(str) {
    let newStr = '';
    if (str) {
      const str2 = str.replace(/[^a-zA-Z0-9 ]/g, ''); // remove special characters
      let wordArr = str2.split(/[ ]/g);
      for (let i in wordArr) {
        if (i > 0) {
          newStr += wordArr[i].charAt(0).toUpperCase() + wordArr[i].slice(1);
        } else {
          newStr += wordArr[i];
        }
      }
    } else {
      return newStr;
    }
    return newStr;
  }

  const [message, setMessage] = useState('');

  let result = '';

  function setOutput() {
    let output = document.getElementById('camelCaseOutput');
    output.value = result;
  }

  return (
    <div>
      <h4>toCamelCase function</h4>
      <textarea
        id="camelCaseInput"
        name="input"
        placeholder="Policy Question string input"
        rows="5"
        cols="100"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
      />
      <br />
      <br />
      <button
        onClick={() => {
          result = toCamelCase(message);
          setOutput();
        }}
      >
        Convert to camelCase
      </button>
      <br />
      <br />
      <textarea
        id="camelCaseOutput"
        name="output"
        placeholder="toCamelCase function output"
        rows="5"
        cols="100"
      />
    </div>
  );
};

export default Utility;

import React, { useState, useContext, useEffect } from 'react';
import Header from './Header';
import Main from './Main';
import { ModalAlertContext } from './ModalAlertQueue.js';
import PCTAlertContext from './PCTAlertContext.js';
import OptionsMenuContext from './OptionsMenuContext.js';
import OptionsMenu from './OptionsMenu.js';

function App() {
  const [alert, setAlert] = useState({
    show: false,
    variant: '',
    heading: '',
    text: '',
    info: '',
    buttons: [],
    onClose: () => {},
  });

  const [optionsMenu, setOptionsMenu] = useState({
    id: 'optionsMenu',
    options: [],
    showOptionsMenu: false,
    showAlert: false,
    alertMessage: '',
    handleAlertClose: () => {},
  });

  const { showModalAlert } = useContext(ModalAlertContext);

  const getCookie = (name) => {
    const cookies = document.cookie.split('; ');
    for (let i = 0; i < cookies.length; i++) {
      const parts = cookies[i].split('=');
      if (parts[0] === name) {
        return decodeURIComponent(parts[1]);
      }
    }
    return '';
  };

  const setConsentBannerAckedCookie = () => {
    document.cookie = 'dodBannerAcknowledged=true; path=/; SameSite=Strict';
  };

  useEffect(() => {
    const dodBannerAcknowledged = getCookie('dodBannerAcknowledged') === 'true';

    if (
      !dodBannerAcknowledged &&
      window.PCT_ENVIRONMENT.dodLoginBannerEnabled
    ) {
      showModalAlert({
        modalAlertTitle: window.PCT_ENVIRONMENT.dodLoginBannerTitle,
        modalAlertBody: window.PCT_ENVIRONMENT.dodLoginBannerMessage,
        backdrop: 'static',
        closeButton: false,
        buttons: [
          {
            label: window.PCT_ENVIRONMENT.dodLoginBannerButtonLabel,
            variant: 'primary',
            onClick: setConsentBannerAckedCookie,
          },
        ],
      });
    }
  }, []);

  return (
    <div id="tt4pPortal" className="row gx-0">
      <PCTAlertContext.Provider value={[alert, setAlert]}>
        <OptionsMenuContext.Provider value={[optionsMenu, setOptionsMenu]}>
          <Header />
          <OptionsMenu />
          <div className="py-2 px-4">
            <Main />
          </div>
        </OptionsMenuContext.Provider>
      </PCTAlertContext.Provider>
    </div>
  );
}

export default App;
